var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        'sw-container': true,
        'sw-container_fluid': _vm.fluid,
        'sw-container_stretched': _vm.stretched,
        'sw-container_padding_s': _vm.paddingY.toUpperCase() === 'S',
        'sw-container_padding_m': _vm.paddingY.toUpperCase() === 'M',
        'sw-container_padding_l': _vm.paddingY.toUpperCase() === 'L',
        'sw-container_padding_all-0': _vm.pa0,
        'sw-container_padding_y-0': _vm.py0,
        'sw-container_has-background-image': !!_vm.bgImage
    },style:({
        backgroundImage: _vm.bgImage ? ("url(" + _vm.bgImage + ")") : 'none',
    })},[_c('div',{staticClass:"sw-container__content"},[_vm._t("default")],2),_vm._v(" "),(_vm.bgColor)?_c('div',{staticClass:"sw-container__overlay",style:({ background: _vm.hexToRgba(_vm.bgColor, parseFloat(_vm.bgOpacity)) })}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }