<template>
    <div
        :class="{
            [$style['node']]: true,
            [$style['node_selected']]: isSelected,
            [$style['node_root']]: node.depth === 0,
            [$style['node_child']]: level === 1,
        }"
        @click="$emit('toggle')"
    >
        <div>
            <div class="sw-text">
                {{ node.name }}
            </div>

            <div
                v-if="!isLeaf"
                :class="[$style['node__counter'], 'sw-text']"
            >
                {{ node.count }}
            </div>
        </div>

        <template v-if="!isLeaf">
            <SvgIconCaretUp
                v-if="active"
                :class="$style['node__caret']"
            />

            <SvgIconCaretDown
                v-else
                :class="$style['node__caret']"
            />
        </template>
    </div>
</template>

<script>
import SvgIconCaretDown from '@@/framework/icons/caret-down.svg'
import SvgIconCaretUp from '@@/framework/icons/caret-up.svg'

export default {
  name: 'TreeNode',

  components: {
    SvgIconCaretDown,
    SvgIconCaretUp
  },

  props: {
    node: {
      type: Object,
      required: true
    },

    level: {
      type: Number,
      default: 0
    },

    active: {
      type: Boolean,
      default: false
    },

    isSelected: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    isLeaf () {
      return !this.node.children || this.node.children.length === 0
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.node {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 12px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media screen and (max-width: @bp-mobile-small) {
    padding-left: 8px;
    padding-right: 8px;
  }

  &_selected,
  &:hover {
    cursor: pointer;
    background: @grey-200;
    border-radius: 4px;
  }

  &_root {
    font-weight: 600;
  }

  &_child {
    font-weight: 400;
    padding-left: 24px;

    @media screen and (max-width: @bp-mobile-small) {
      padding-left: 16px;
    }
  }

  &__counter {
    font-size: 12px;
    color: @grey-800;
    margin-left: 8px;
  }

  &__caret {
    color: #14142A;
    margin-right: 8px;
  }
}
</style>
