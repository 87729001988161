<template>
    <span
        :class="{
            'sw-input': true,
            'sw-input_filled': !!value,
            'sw-input_labelled': label,
            'sw-input_search': search,
            'sw-input_focused': focused,
            'sw-input_invalid': invalid,
            'sw-input_disabled': disabled,
            'sw-input_multiline': multiline,
            'sw-input_roundings_none': $simlaweb.settings.roundings === ROUNDINGS.NONE,
            'sw-input_roundings_small': $simlaweb.settings.roundings === ROUNDINGS.SMALL,
            'sw-input_roundings_large': $simlaweb.settings.roundings === ROUNDINGS.LARGE && !multiline,
            'sw-input_roundings_large_multiline': $simlaweb.settings.roundings === ROUNDINGS.LARGE && multiline,
            'sw-input_dense': dense,
            'sw-input_required': required,
        }"
        v-bind="$attrs"
    >
        <label class="sw-input__inner">
            <component
                :is="variants.length > 0 ? 'SwInputAutocompletion' : 'div'"
                :list="variants"
                :open="focused && showVariants"
                :area-focus="variants.length > 0 ? focused : null"
                @select="variants.length > 0 ? onSelect($event) : null"
            >
                <component
                    :is="multiline ? 'textarea' : 'input'"
                    :id="name"
                    ref="input"
                    v-awesome-mask="mask"
                    :name="name"
                    :type="type"
                    :value="value || value !== 0 ? value : ''"
                    :autocomplete="autocomplete"
                    :readonly="readonly"
                    :disabled="disabled"
                    class="sw-input__input"
                    @input="onInput($event.target.value)"
                    @focus="onFocus"
                    @blur="onBlur"
                    @change="$emit('change', $event.target.value)"
                    @keyup="event => $emit('keyup', event)"
                />
            </component>

            <span
                v-if="label"
                class="sw-input__label"
            >
                {{ label }}
            </span>

            <span
                v-if="search"
                class="sw-input__search-icon"
            >
                <SvgIconSearch />
            </span>

            <span
                v-if="clearable && value && !disabled && !$slots.icon"
                class="sw-input__right-icon"
                @click="clear"
            >
                <SvgIconCrossOutlined />
            </span>

            <span v-if="$slots.icon" class="sw-input__right-icon">
                <slot name="icon" />
            </span>
        </label>
    </span>
</template>

<script>
import AwesomeMask from 'awesome-mask'

import SvgIconCrossOutlined from '@@/framework/icons/cross-outlined.svg'
import SvgIconSearch from '@@/framework/icons/search.svg'
import SwInputAutocompletion from './SwInputAutocompletion'

import { ROUNDINGS } from '@@/framework/types'

export default {
  name: 'SwInput',

  components: {
    SvgIconCrossOutlined,
    SvgIconSearch,
    SwInputAutocompletion,
  },

  directives: {
    awesomeMask: AwesomeMask
  },

  props: {
    type: {
      type: String,
      default: 'text'
    },

    name: {
      type: String,
      required: true
    },

    label: {
      type: String,
      default: ''
    },

    value: {
      type: [String, Number],
      default: ''
    },

    mask: {
      type: String,
      default: ''
    },

    autocomplete: {
      type: String,
      default: 'on'
    },

    variants: {
      type: Array,
      default: () => []
    },

    clearable: {
      type: Boolean,
      default: true
    },

    invalid: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    search: {
      type: Boolean,
      default: false
    },

    dense: {
      type: Boolean,
      default: false
    },

    multiline: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    focused: false
  }),

  computed: {
    ROUNDINGS: () => ROUNDINGS,

    showVariants () {
      return this.variants.length > 1 || (
        this.variants.length === 1 &&
        this.variants[0] !== this.value
      )
    }
  },

  methods: {
    clear () {
      if (this.$refs.input) {
        this.$refs.input.value = ''
      }
      this.$emit('input', '')
      this.$emit('clear', '')
    },

    onBlur (event) {
      this.focused = false
      this.$emit('blur', event)
    },

    onFocus (e) {
      this.focused = true
      this.$emit('focus', e)
    },

    onInput (value) {
      this.$emit('input', this.type === 'number' ? Number(value) : value)
    },

    onSelect (value) {
      if (this.$refs.input) {
        this.$refs.input.value = value
        this.$refs.input.blur()
      }

      this.$emit('select', value)
    }
  }
}
</script>

<style lang="less">
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.sw-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 56px;
  background: #FFFFFF;
  border: 1px solid @grey-500;
  box-sizing: border-box;
  position: relative;

  &_multiline {
    height: 110px;
    padding: 0;
    background: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    outline: none;
  }

  &_dense { height: 40px; }

  &_roundings_none { border-radius: 0; }
  &_roundings_small { border-radius: 8px; }
  &_roundings_large { border-radius: 32px; }
  &_roundings_large_multiline { border-radius: 16px; }

  * {
    box-sizing: border-box;
  }

  &__inner {
    height: 100%;
    box-shadow: 0 0 0 1px transparent;

    &:hover {
      box-shadow: 0 0 0 1px @brand-500;
    }
  }

  &_roundings_none &__inner { border-radius: 0; }
  &_roundings_small &__inner { border-radius: 8px; }
  &_roundings_large &__inner { border-radius: 32px; }
  &_roundings_large_multiline &__inner { border-radius: 16px; }

  &_focused &__inner {
    width: 100%;
    box-shadow: 0 0 0 2px @brand-200;
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &_invalid &__inner {
    width: 100%;
    border: 1px solid @red-500;
    box-shadow: 0 0 0 2px @red-100;
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: 0;
  }

  &_disabled &__inner {
    background: @grey-200;
    border: 1px solid @grey-500;

    &:hover {
      box-shadow: none;
    }
  }

  &__label {
    display: inline-block;
    width: calc(100% - 48px);
    padding-left: 16px;

    font-family: var(--sw-font-texts);
    font-size: 16px;
    line-height: 24px;
    color: @grey-700;

    transition: ease-in-out 0.3s;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;

    position: absolute;
    top: 15px;
    left: 0;
  }

  &_invalid &__label {
    color: @red-500;
  }

  &_search &__label {
    padding-left: 44px;
  }

  &_dense &__label {
    padding: 0 12px;
    top: 7px;
  }

  &_dense&_filled &__label,
  &_dense&_focused &__label {
    display: none;
  }

  &_dense&_search &__label {
    padding-left: 40px;
  }

  &_labelled&_filled &__label,
  &_labelled&_focused &__label {
    font-size: 12px;
    line-height: 16px;
    transform: translateY(-8px);
  }

  &__input {
    width: 100%;
    padding: 0 48px 0 16px;

    background: none !important;
    border: none !important;
    border-radius: inherit;
    outline: none;

    font-family: var(--sw-font-texts);
    font-size: 16px;
    line-height: 24px;

    position: relative;
    top: 24px;
    z-index: 1000;

    &[type="number"] {
      -moz-appearance:textfield;
    }

    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-background-clip: text;
      transition: background-color 5000s ease-in-out 0s;
    }
    // delete cross inside input for IE
    &[type="text"]::-ms-clear,
    &[type="text"]::-ms-reveal {
      display: none;
      width : 0;
      height: 0;
    }

    // delete cross inside input with type "search"
    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button,
    &[type="search"]::-webkit-search-results-decoration {
      display: none;
    }
  }

  &_multiline &__input {
    height: calc(100% - 32px);
    overflow: hidden;
    resize: none;
    box-shadow: none;
    position: absolute;
    top: 24px;
    left: 0;
  }

  &_search &__input {
    top: 15px;
    padding-left: 44px;
  }

  &_dense &__input {
    width: calc(100% - 24px);
    height: 38px;
    padding: 0 12px;
    top: 0;
  }

  &_dense&_filled &__input {
    transform: none;
  }

  &_dense&_search &__input {
    padding-left: 40px;
  }

  &_disabled &__input {
    color: @grey-800;
  }

  &__search-icon {
    color: @grey-700;
    position: absolute;
    top: 14px;
    left: 12px;
  }

  &_focused &__search-icon {
    color: @brand-500;
  }

  &_filled:not(&_disabled):not(&_focused) &__search-icon,
  &_invalid:not(&_disabled) &__search-icon {
    color: @black-title;
  }

  &_dense&_search &__search-icon {
    top: 6px;
    left: 8px;
  }

  &__right-icon {
    display: flex;
    align-items: center;
    width: 24px;
    height: 100%;
    text-align: center;
    cursor: pointer;
    position: absolute;
    right: 17px;
    z-index: 1003;
    top: 0;
  }

  &_dense &__right-icon {
    right: 8px;
  }

  &_required &__label::after {
    content: "*";
    position: absolute;
  }
}
</style>
