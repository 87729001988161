<script>
export default {
  methods: {
    buildMetaForCustomScripts () {
      const metas = {}

      const loadMetas = (jsonString) => {
        if (!jsonString) {
          return
        }

        try {
          /**
           * @type {[{
           *    hid: string,
           *    tag: string,
           *    innerHTML: string,
           *    body: boolean
           * }]}
           */
          const arrayOfMetas = JSON.parse(jsonString)

          if (Array.isArray(arrayOfMetas)) {
            for (const meta of arrayOfMetas) {
              const tag = meta.tag
              delete meta.tag

              if (metas[tag] === undefined) {
                metas[tag] = []
              }

              metas[tag].push(meta)
            }
          }
        } catch (e) {
        }
      }

      loadMetas(this.$store.getters.codeBeforeEndHeadMeta)
      loadMetas(this.$store.getters.codeBeforeEndBodyMeta)

      return {
        ...metas,
        __dangerouslyDisableSanitizers: ['script', 'meta', 'noscript']
      }
    }
  }
}
</script>
