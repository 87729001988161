import { ApolloError } from 'apollo-client'

export type PropertyPath = string

export type ErrorMessage = string
export type ErrorMessageList = Record<PropertyPath, ErrorMessage[]>

export type Violation = {
  code: string,
  message: string,
}

export type ViolationList = Record<PropertyPath, Violation[]>

export const VALIDATION_EXTENSION = 'arguments_validation_error'

export const getValidationErrors = (error: ApolloError): ErrorMessageList|undefined => {
  const [first] = error.graphQLErrors || []

  if (first?.extensions?.category === VALIDATION_EXTENSION) {
    const violations: ViolationList = first.extensions.validation
    const errors: Record<string, string[]> = {}

    for (const path in violations) {
      errors[path] = first.extensions.validation[path].map((v: Violation) => v.message)
    }

    return errors
  }

  return undefined
}
