<template>
    <component
        :is="tag"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot />
    </component>
</template>

<script>
export default {
  name: 'SwForm',

  provide () {
    return {
      __SwForm_add: this.add.bind(this),
      __SwForm_remove: this.remove.bind(this)
    }
  },

  props: {
    tag: {
      type: String,
      default: 'form'
    }
  },

  beforeCreate () {
    this.__SwForm_registry = new Map()
  },

  beforeDestroy () {
    this.__SwForm_registry = undefined
  },

  methods: {
    add (id, field) {
      if (this.__SwForm_registry) {
        this.__SwForm_registry.set(id, field)
      }
    },

    remove (id) {
      if (this.__SwForm_registry) {
        this.__SwForm_registry.delete(id)
      }
    },

    /** @return {Promise<boolean>} */
    async validate () {
      /** @type {Promise<ValidationResult|null>[]} */
      const all = []

      this.__SwForm_registry.forEach((field) => {
        all.push(field.validate())
      })

      const results = await Promise.all(all)

      return results.reduce((valid, result) => valid && result?.valid, true)
    }
  }
}
</script>
