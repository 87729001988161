<template>
    <SimpleModalWindow
        :class="$style['window']"
        :opened="$store.getters['modals/opened']('publicDocument')"
        :width="880"
        @close="close"
    >
        <template v-if="document.name" #title>
            {{ document.name }}
        </template>

        <div
            v-if="document.value"
            class="sw-text"
            v-html="document.value"
        />
    </SimpleModalWindow>
</template>

<script>
import SimpleModalWindow from '@@/website/components/common/SimpleModalWindow'
import { GET_PUBLIC_DOCUMENT } from '@@/graphQL/web/queries'

export default {
  name: 'ModalPersonal',

  components: {
    SimpleModalWindow
  },

  apollo: {
    document: {
      query: GET_PUBLIC_DOCUMENT,
      variables () {
        return {
          siteCode: this.$store.state.siteCode,
          key: this.$store.getters.publicDocumentKey
        }
      }
    }
  },

  data () {
    return {
      document: {
        name: '',
        value: ''
      }
    }
  },

  methods: {
    close () {
      this.$store.commit('modals/close', 'publicDocument')
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.window {
  @media screen and (max-width: @bp-mobile-small) {
    width: 100%;
    padding: 0;
    :global(.sw-modal-window__container) {
      max-width: 100% !important;
      max-height: 100% !important;
      height: 100%;
    }
  }
}
</style>
