<template>
    <SimpleModalWindow
        :class="$style['window']"
        :opened="$store.getters['modals/opened']('recoveryRequest')"
        @close="close('recoveryRequest')"
    >
        <template #title>
            {{ $t('auth_recovery') }}
        </template>

        <SwForm
            ref="form"
            :class="$style['window__form']"
            @submit.prevent="send"
        >
            <SwFormInput
                v-model="email"
                :label="$t('auth_email')"
                :class="$style['window__field']"
                :errors="errors['email'] || []"
                name="email"
                rules="email|email_acceptable"
                clearable
                @input="$set(errors, 'email', [])"
            />

            <SwButton
                :class="$style['window__button']"
                :disabled="$store.state.isLoading"
                type="submit"
                wide
            >
                {{ $t('auth_password_recovery') }}
            </SwButton>
        </SwForm>

        <div :class="$style['window__auth']">
            <a @click="openRegisterModal">{{ $t('auth_register_action') }}</a>
            <a @click="openLoginModal">{{ $t('auth_login_action') }}</a>
        </div>

        <UiLoader
            v-show="$store.state.isLoading"
            fixed
            overlay
        />
    </SimpleModalWindow>
</template>

<script>
import SimpleModalWindow from '@@/website/components/common/SimpleModalWindow'
import { isApolloError } from 'apollo-client'
import { getValidationErrors } from '@@/website/utils/graphql'

import { SEND_RECOVERY_LINK } from '@@/graphQL/web'

export default {
  name: 'ModalPasswordRecoveryRequest',

  components: {
    SimpleModalWindow
  },

  data: () => ({
    email: '',
    errors: {}
  }),

  methods: {
    async send () {
      if (this.$store.state.admin) {
        this.close('recoveryRequest')
        this.open('forbidden')
        return
      }

      if (this.$store.state.isLoading) {
        return
      }

      if (this.$refs.form && !(await this.$refs.form.validate())) {
        this.$notifications.error(this.$t('form_contains_errors'))
        return
      }

      this.$store.commit('lock')

      try {
        const { data } = await this.$apollo.mutate({
          mutation: SEND_RECOVERY_LINK,
          variables: {
            input: { email: this.email }
          }
        })

        if (data.recoverySendLink.success) {
          this.$store.commit('recovery/email', this.email)
          this.close('recoveryRequest')
          this.close('recoveryConfirm')
        }
      } catch (e) {
        const errors = getValidationErrors(e)
        if (isApolloError(e) && errors !== undefined) {
          this.errors = errors
        } else {
          console.error(e)
        }
      }

      this.$store.commit('unlock')
    },

    openLoginModal () {
      this.close('recoveryRequest')
      this.open('login')
    },

    openRegisterModal () {
      this.close('recoveryRequest')
      this.open('registration')
    },

    open (name) {
      this.$store.commit('modals/open', name)
    },

    close (name) {
      this.$store.commit('modals/close', name)
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.window {
  padding: 16px 24px;
  z-index: 100;
  @media screen and (max-width: @bp-mobile-small) {
    width: 100%;
    padding: 0;
  }

  :global(.sw-modal-window__container) {
    @media screen and (max-width: @bp-mobile-small) {
      height: 100%;
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }

  :global(.sw-panel) {
    padding: 16px 20px;
    @media screen and (max-width: @bp-mobile-small) {
      box-sizing: border-box;
      height: 100%;
      margin-bottom: 0;
    }
  }

  :global(.sw-separator) {
    min-width: calc(100% + 20px * 2);
    margin: 0 -20px 16px -20px;
  }

  &__form {
    @media screen and (max-width: @bp-mobile-small) {
      margin-top: auto;
    }
  }

  &__field :global(.sw-input) {
    @media screen and (max-width: @bp-mobile-small) {
      min-height: 56px;
    }
  }

  &__field > label {
    font-style: normal;
    font-weight: 400;
  }

  &__button {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__auth {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    .text-link;
    @media screen and (max-width: @bp-mobile-small) {
      margin-bottom: auto;
    }
  }

  &__auth a {
    color: inherit;
  }

  &__auth a:hover {
    cursor: pointer;
  }
}
</style>
