<template>
    <li>
        <TreeNode
            :node="item"
            :level="level"
            :active="isOpen"
            :is-selected="selectedId === item.id"
            @toggle="toggle(item.id)"
        />

        <template v-if="!isLeaf">
            <ul v-show="isOpen" :class="$style['subtree']">
                <template v-for="(child, index) in item.children">
                    <TreeSubtree
                        v-show="(index < 10) || showAll"
                        :key="index"
                        :item="child"
                        :level="level + 1"
                        @closeModal="$emit('closeModal')"
                        @selectCategory="setSelected($event)"
                    />
                </template>
            </ul>

            <div
                v-if="item.children && item.children.length > 10"
                v-show="isOpen"
                :class="$style['show-all-link']"
                @click="showAll = !showAll"
            >
                <div class="sw-text">
                    {{ showAll ? $t('text_hide') : $t('text_view_all') }}
                </div>
            </div>
        </template>
    </li>
</template>

<script>
import TreeNode from './TreeNode'

export default {
  name: 'TreeSubtree',

  components: {
    TreeNode
  },

  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    },

    selectedId: {
      type: Number,
      default: 0
    },

    level: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      isOpen: false,
      showAll: false,
      selected: this.selectedId
    }
  },

  computed: {
    isLeaf () {
      return !this.item.children || this.item.children.length === 0
    }
  },

  watch: {
    selectedId () {
      this.isOpen = this.item.id === this.selectedId
    }
  },

  mounted () {
    this.isOpen = this.item.id === this.selected
  },

  methods: {
    toggle (id) {
      if (this.isLeaf) {
        this.$emit('closeModal')
      } else {
        this.isOpen = !this.isOpen
      }
      this.setSelected(id)
    },

    setSelected (id) {
      this.selected = id
      this.$emit('selectCategory', this.selected)
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.subtree {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.show-all-link {
  padding-left: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  color: @brand-500;
  cursor: pointer;
}
</style>
