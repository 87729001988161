<template>
    <div class="sw-input-autocomplete-wrap">
        <div ref="target" class="sw-input-autocomplete__target">
            <slot />
        </div>

        <UiPopper
            :shown="open"
            :target="() => $refs.target"
            :triggers="[]"
            :delay="{ hide: 150 }"
            :style="{
                minWidth: `${targetWidth}px`,
                fontFamily: `'${$simlaweb.settings.fonts.texts}', -apple-system, BlinkMacSystemFont, sans-serif`,
            }"
            class="sw-input-autocomplete"
            offset-main-axis="4"
            placement="bottom-start"
        >
            <div ref="dropdown" class="sw-input-autocomplete__inner">
                <div
                    v-for="(option, i) in list"
                    :key="`item-${i}`"
                    :class="{
                        'sw-input-autocomplete__item': true,
                        'sw-input-autocomplete__item_hover': activeItem === i + 1
                    }"
                    @click.stop="$emit('select', option)"
                    @mouseenter="activeItem = i + 1"
                    @mouseleave="activeItem = 0"
                >
                    {{ option || '' }}
                </div>
            </div>
        </UiPopper>
    </div>
</template>

<script>
const KEY_ARROW_DOWN = 'ArrowDown'
const KEY_ARROW_UP = 'ArrowUp'
const KEY_ENTER = 'Enter'

export default {
  name: 'SwInputAutocompletion',

  props: {
    list: {
      type: Array,
      default: () => []
    },

    open: {
      type: Boolean,
      default: false
    },

    areaFocus: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      targetWidth: 0,
      activeItem: 0
    }
  },

  watch: {
    list () {
      if (this.$refs.dropdown) {
        this.$nextTick(() => {
          this.$refs.dropdown.scrollTop = 0
        })
      }
    }
  },

  mounted () {
    this.targetWidth = this.$el.clientWidth

    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', this.setActiveItem)
    }
  },

  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('keydown', this.setActiveItem)
    }
  },

  methods: {
    setActiveItem (event) {
      if (![KEY_ARROW_DOWN, KEY_ARROW_UP, KEY_ENTER].includes(event.key)) {
        return
      }

      if (event.key === KEY_ENTER && this.list[this.activeItem - 1] && this.areaFocus) {
        event.preventDefault()
        this.$emit('select', this.list[this.activeItem - 1])
        return
      }

      if (event.key === KEY_ARROW_DOWN) {
        this.activeItem = this.activeItem + 1 <= this.list.length ? this.activeItem + 1 : 1
      }

      if (event.key === KEY_ARROW_UP) {
        this.activeItem = this.activeItem - 1 > 0 ? this.activeItem - 1 : this.list.length
      }

      this.$nextTick(() => {
        const dropdown = this.$refs.dropdown
        const el = dropdown.querySelector('.sw-input-autocomplete__item_hover')

        if (!el || !dropdown) {
          return
        }

        if (el.offsetTop + el.clientHeight < dropdown.scrollTop || el.offsetTop > dropdown.scrollTop + dropdown.clientHeight) {
          dropdown.scrollTop = el.offsetTop
        }
      })
    }
  }
}
</script>

<style lang="less">
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.sw-input-autocomplete {
  --omnica-popper-roundings: @border-radius-l;
  max-width: 500px;
  max-height: 210px;
  z-index: 10;

  &:focus {
    outline: none !important;
  }

  & .omnica-popper__content {
    padding: @spacing-xs;
  }

  &__target {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  &__item {
    padding: 6px 8px;
    border-radius: @border-radius-m;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    transition: background-color .2s linear;

    &_hover {
      background: @grey-200;
    }
  }

  &__inner {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}

.sw-input-autocomplete-wrap {
  height: 100%;
}
</style>
