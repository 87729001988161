import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory/lib/types'
import {
  ActionTree,
  GetterTree,
  MutationTree,
  Store,
} from 'vuex'
// @ts-ignore
import uniqid from 'uniqid'
import {
  GqlCommonSettings,
  GqlDesignSettings,
  GqlUser
} from '@@/website/types'

import {
  ColorPalette,
  CASING,
  ROUNDINGS
} from '@@/framework/types'
import { GET_SETTINGS, USER_INFO } from '@@/graphQL/web'

type GraphQLClient = ApolloClient<NormalizedCacheObject>

type SettingsQueryPayload = {
  settings: GqlCommonSettings;
  designSettings: GqlDesignSettings;
}

export type State = {
  siteCode: string;
  settings: Partial<GqlCommonSettings>;
  uri: string;
  host: string;
  publicDocumentKey: string;
  isLoading: boolean;
}

export const state = (): State => ({
  siteCode: '',
  settings: {
    can_buy_without_authorization: true,
    can_show_characteristic: true,
    favicon: '',
    default_currency: '',
    system_language: ''
  },
  uri: '',
  host: '',
  publicDocumentKey: 'document_privacy_policy',
  isLoading: false
})

export const getters: GetterTree<State, State> = {
  faviconHref: (state: State) => state.settings.favicon,
  codeBeforeEndHead: (state: State) => state.settings.codeBeforeEndHead,
  codeBeforeEndBody: (state: State) => state.settings.codeBeforeEndBody,
  codeBeforeEndHeadMeta: (state: State) => state.settings.codeBeforeEndHeadMeta,
  codeBeforeEndBodyMeta: (state: State) => state.settings.codeBeforeEndBodyMeta,
  publicDocumentKey: (state: State) => state.publicDocumentKey
}

export const mutations: MutationTree<State> = {
  siteCode (state: State, siteCode: string) {
    state.siteCode = siteCode
  },

  siteHost (state: State, host: string) {
    state.host = host
  },

  siteUrl (state: State, uri: string) {
    state.uri = uri
  },

  siteSettings (state: State, settings: Partial<GqlCommonSettings>) {
    state.settings = {
      ...state.settings,
      ...settings
    }
  },

  publicDocumentKey: (state: State, publicDocumentKey: string) => {
    state.publicDocumentKey = publicDocumentKey
  },

  lock (state: State) {
    state.isLoading = true
  },

  unlock (state: State) {
    state.isLoading = false
  }
}

export const actions: ActionTree<State, State> = {
  async nuxtServerInit ({ commit, dispatch }) {
    commit('lock')

    await dispatch('setup')
    await dispatch('autologin')

    commit('unlock')
  },

  async setup (this: Store<State>, { commit, state }, options = {}) {
    const apollo: GraphQLClient = this.app.apolloProvider?.defaultClient as GraphQLClient
    const {
      data: {
        settings,
        designSettings
      }
    }: { data: SettingsQueryPayload } = await apollo.query({
      query: GET_SETTINGS,
      variables: { siteCode: state.siteCode },
      ...options,
    })

    commit('siteSettings', settings)

    this.$simlaweb.settings.buttonsTextCasing = designSettings.buttonTextUpperCase ? CASING.UPPERCASE : CASING.INITIAL
    this.$simlaweb.settings.colors.main = designSettings.mainColor as keyof ColorPalette
    this.$simlaweb.settings.colors.background = designSettings.backgroundColor
    this.$simlaweb.settings.favicon = designSettings.favicon
    this.$simlaweb.settings.fonts.headings = designSettings.headingFont
    this.$simlaweb.settings.fonts.texts = designSettings.textFont
    this.$simlaweb.settings.roundings = designSettings.borderRadius as ROUNDINGS

    const locale = typeof window !== 'undefined'
      // @ts-ignore
      ? window.__NUXT__?.config.app?.LANGUAGE || state.settings.system_language
      : state.settings.system_language

    await this.$i18n.setLocale(locale.toLowerCase())
  },

  async autologin (this: Store<State>, { commit, dispatch }) {
    const apollo: GraphQLClient = this.app.apolloProvider?.defaultClient as GraphQLClient
    await apollo.resetStore()
    if (this.app.context.$apolloHelpers.getToken()) {
      try {
        const { data } = await apollo.query({ query: USER_INFO })
        if (data) {
          await dispatch('login', { user: data.info })
          commit('user/set', { authenticated: true })
        }
      } catch (e) {
        console.error(e)
      }
    }
  },

  async login (this: Store<State>, { commit }, { user, token }: { user: GqlUser, token?: string }) {
    const city = { id: user.address?.cityId || 0, name: user.address?.city || '' }
    const street = { id: user.address?.streetId || 0, name: user.address?.street || '' }

    commit('lock')

    commit('user/set', {
      firstName: user.firstName,
      lastName: user.lastName,
      gender: user.gender,
      birthday: user.birthday,
      email: user.email,
      phone: user.phone,
      address: {
        city: { ...city },
        street: { ...street },
        building: user.address?.building || '',
        flat: user.address?.flat || '',
      },
      bonuses: user.info.bonuses,
    })

    if (token) {
      await this.app.context.$apolloHelpers.onLogin(token)
      commit('user/set', { authenticated: true })
    }

    commit('checkout/reset')
    commit('checkout/setCustomer', {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
    })
    commit('checkout/setDeliveryAddress', {
      city: { ...city },
      street: { ...street },
      building: user.address?.building || '',
      flat: user.address?.flat || '',
    })

    commit('unlock')
  },

  async logout (this: Store<State>, { commit }) {
    commit('checkout/reset')
    commit('user/reset')

    await this.app.context.$apolloHelpers.onLogout()
    if (this.$cookies.get('X-IDENTITY')) {
      this.$cookies.set('X-IDENTITY', `${uniqid()}-${uniqid()}`)
    }

    await this.app.apolloProvider?.defaultClient.resetStore()
  },

  async sendOrder (this: Store<State>, { state, commit, dispatch }, { noRedirect = false } = {}) {
    commit('lock')

    try {
      const result = await dispatch('checkout/send', {
        siteCode: state.siteCode,
        returnUrl: `${state.uri.split('/api')[0]}/order?success=true`
      })

      if (result?.link) {
        document.location.assign(result?.link)
      } else if (result.done && !noRedirect) {
        await this.$router.push({ name: 'order', params: { success: result?.success } })
      }

      commit('unlock')
      return result.done
    } catch (error) {
      commit('unlock')
      return false
    }
  },

  openPublicDocument ({ commit }, publicDocumentKey) {
    commit('publicDocumentKey', publicDocumentKey)
    commit('modals/open', 'publicDocument')
  }
}
