<template>
    <ValidationProvider
        ref="validation"
        v-slot="{ errors: violations }"
        :name="name"
        :rules="rules"
        slim
    >
        <div :data-qa-name="name">
            <SwInput
                :type="type"
                :name="name"
                :label="label"
                :value="value"
                :autocomplete="autocomplete"
                :variants="variants"
                :mask="mask"
                :invalid="errors.length + violations.length > 0"
                :clearable="clearable"
                :multiline="multiline"
                :disabled="disabled"
                :readonly="readonly"
                :dense="dense"
                :required="required"
                v-on="$listeners"
                @blur="validate(value)"
            >
                <template v-if="$slots.icon" #icon>
                    <slot name="icon" />
                </template>
            </SwInput>

            <div
                v-if="errors.length + violations.length > 0"
                class="sw-form-input__error"
            >
                {{ [...errors, ...violations][0] }}
            </div>
        </div>
    </ValidationProvider>
</template>

<script>
import SwInput from '@@/framework/components/SwInput'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'SwFormInput',

  components: {
    SwInput,
    ValidationProvider,
  },

  inject: {
    __SwForm_add: { default: () => () => {} },
    __SwForm_remove: { default: () => () => {} }
  },

  props: {
    type: {
      type: String,
      default: 'text'
    },

    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      default: ''
    },

    value: {
      type: [String, Number],
      default: ''
    },

    rules: {
      type: [String, Object],
      default: ''
    },

    errors: {
      type: Array,
      default: () => [],
    },

    mask: {
      type: String,
      default: ''
    },

    autocomplete: {
      type: String,
      default: 'on'
    },

    variants: {
      type: Array,
      default: () => []
    },

    multiline: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    dense: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false
    },
  },

  mounted () {
    this.__SwForm_add(this.name, this)
  },

  beforeDestroy () {
    this.__SwForm_remove(this.name)
  },

  methods: {
    validate (value) {
      return this.$refs.validation
        ? this.$refs.validation.validate(value !== undefined ? value : this.value)
        : Promise.resolve(null)
    },
  }
}
</script>

<style lang="less">
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.sw-form-input {
  &__error {
    color: @red-500;
    margin-top: 8px;
  }
}
</style>
