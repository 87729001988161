<template>
    <SwContainer>
        <div :class="[$style['page-error'], $style[`page-error_${code}`]]">
            <div :class="$style['page-error__image']">
                <SvgSpriteError403 v-if="code === 403" />
                <SvgSpriteError404 v-else-if="code === 404" />
                <SvgSpriteError500 v-else-if="code === 500" />
                <SvgSpriteError503 v-else-if="code === 503" />
                <SvgSpriteError v-else-if="code === 0" />
            </div>

            <div :class="$style['page-error__content']">
                <div v-if="code !== 0" class="sw-text">
                    {{ $t('admin_error') }} {{ code }}
                </div>

                <h1 :class="[$style['page-error__title'], 'sw-title']">
                    {{ $t(`error_${code}_title`) }}
                </h1>

                <div class="sw-text">
                    {{ $t(`error_${code}_text`) }}
                </div>

                <SwButton
                    v-if="code !== 503 && code !== 0"
                    :class="$style['page-error__button']"
                    to="/"
                >
                    {{ $t('error_btn_home') }}
                </SwButton>
            </div>
        </div>
    </SwContainer>
</template>

<script>
import SvgSpriteError from './sprites/error.svg'
import SvgSpriteError403 from './sprites/error-403.svg'
import SvgSpriteError404 from './sprites/error-404.svg'
import SvgSpriteError500 from './sprites/error-500.svg'
import SvgSpriteError503 from './sprites/error-503.svg'

export default {
  name: 'PageError',

  components: {
    SvgSpriteError,
    SvgSpriteError403,
    SvgSpriteError404,
    SvgSpriteError500,
    SvgSpriteError503
  },

  props: {
    code: {
      type: Number,
      default: 404
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.page-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 520px;
    text-align: center;
    margin-top: 37px;

    @media screen and (max-width: @bp-mobile-big) { margin-top: 30px; }
    @media screen and (max-width: @bp-mobile-small) { margin-top: 30px; }
  }

  &__title {
    margin: 0 0 16px;
  }

  &__image svg {
    max-width: 100%;
    height: auto;
  }

  &__button {
    margin-top: 40px;

    @media screen and (max-width: @bp-mobile-big) { margin-top: 32px; }
    @media screen and (max-width: @bp-mobile-small) { padding: 7px 24px; }
  }

  &_403 { padding: 52px 0; }
  &_404 { padding: 99px 0; }
  &_500 { padding: 103px 0; }
  &_503 { padding: 83px 0; }

  &_403 &__content { margin-top: 48px; }

  @media screen and (max-width: @bp-mobile-big) {
    &_403 { padding: 37px 0; }
    &_404 { padding: 67px 0; }
    &_500 { padding: 70px 0; }
    &_503 { padding: 54px 0; }

    &_403 &__image svg { max-width: 280px; }
    &_404 &__image svg { max-width: 373px; }
    &_500 &__image svg { max-width: 298px; }
    &_503 &__image svg { max-width: 266px; }

    &_403 &__content { margin-top: 36px; }
    &_404 &__content { margin-top: 30px; }
    &_500 &__content { margin-top: 30px; }
    &_503 &__content { margin-top: 32px; }
  }

  @media screen and (max-width: @bp-mobile-small) {
    &_403 { padding: 48px 0; }
    &_404 { padding: 74px 0; }
    &_503 { padding: 58px 0; }

    &_403 &__image svg { max-width: 210px; }
    &_404 &__image svg { max-width: 280px; }
    &_500 &__image svg { max-width: 224px; }
    &_503 &__image svg { max-width: 200px; }

    &_403 &__content { margin-top: 32px; }
    &_404 &__content { margin-top: 24px; }
  }
}
</style>
