import {
  ColorPalette,
  ColorGetter
} from './types'

/** @see https://www.figma.com/file/szHSKZuIVZBjrwWVtt7Q4N/Simla-Web-Library?node-id=1086%3A157 */
const palette: ColorPalette & ColorGetter = {
  get (name: keyof ColorPalette, brand: number) {
    return name in this
      ? this[name][brand]
      : this.blue[brand]
  },

  blue: {
    0: 'rgba(76, 110, 245, 0.1)',
    100: '#EDF2FF',
    200: '#BAC8FF',
    300: '#91A7FF',
    400: '#5C7CFA',
    500: '#4C6EF5',
    600: '#4263EB',
    700: '#3B5BDB',
    800: '#364FC7'
  },

  green: {
    0: '#12B886',
    100: '#E3FDF4',
    200: '#C3FAE8',
    300: '#96F2D7',
    400: '#63E6BE',
    500: '#12B886',
    600: '#0CA678',
    700: '#099268',
    800: '#087F5B'
  },

  red: {
    0: '#FA5252',
    100: '#FFF0F0',
    200: '#FFE3E3',
    300: '#FFC9C9',
    400: '#FF8787',
    500: '#FA5252',
    600: '#F03E3E',
    700: '#E03131',
    800: '#C92A2A'
  },

  pink: {
    0: '#F06595',
    100: '#FCE3EC',
    200: '#F9C3D5',
    300: '#F6A2BF',
    400: '#F386AC',
    500: '#F06595',
    600: '#ED457F',
    700: '#EB2E6F',
    800: '#E8175F'
  },

  orange: {
    0: '#FF922B',
    100: '#FFF1E0',
    200: '#FFE8CC',
    300: '#FFD8A8',
    400: '#FFA94D',
    500: '#FF922B',
    600: '#F76707',
    700: '#E8590C',
    800: '#D9480F'
  },

  yellow: {
    0: '#FFD43B',
    100: '#FFF9E0',
    200: '#FFF3BF',
    300: '#FFEC99',
    400: '#FFE066',
    500: '#FFD43B',
    600: '#F59F00',
    700: '#F08C00',
    800: '#E67700'
  },

  purple: {
    0: '#9D1CD8',
    100: '#F4E4FB',
    200: '#E4BBF6',
    300: '#D393F1',
    400: '#BB58E9',
    500: '#9D1CD8',
    600: '#8317B5',
    700: '#691390',
    800: '#5F1183'
  },

  turquoise: {
    0: '#50B1E2',
    100: '#E5F3FB',
    200: '#C2E4F5',
    300: '#A8D8F0',
    400: '#7CC5E9',
    500: '#50B1E2',
    600: '#2DA1DC',
    700: '#218EC5',
    800: '#1D7EAF'
  },

  black: {
    0: '#212529',
    100: '#EEF0F1',
    200: '#D5D9DD',
    300: '#8E99A4',
    400: '#505963',
    500: '#212529',
    600: '#171A1C',
    700: '#101214',
    800: '#050506'
  },

  brown: {
    0: '#915925',
    100: '#F7EADE',
    200: '#EACBAE',
    300: '#DFAE81',
    400: '#CD823C',
    500: '#915925',
    600: '#7A4B1F',
    700: '#663E1A',
    800: '#492D13'
  }
}

export default palette
