




























































































import type { PropType } from 'vue'
import type {
  FilterDefinition,
  FilterModel,
} from '@@/website/utils/filters'

import Vue from 'vue'

import CollapseBox from '@@/website/components/common/FilterForm/CollapseBox.vue'
import { createQuery, createFilterModel, createFilters } from '@@/website/utils/filters'

const EXPANDED_MAX = 6

export default Vue.extend({
  name: 'FilterForm',

  components: {
    CollapseBox
  },

  props: {
    filters: {
      type: Array as unknown as PropType<FilterDefinition[]>,
      default: () => []
    }
  },

  data: () => ({
    filtersModel: [] as FilterModel[],
    update: 0,
    vitalOnly: true
  }),

  computed: {
    definitions (): Map<string, FilterDefinition> {
      return new Map(this.filters.map(filter => [filter.key, filter]))
    },

    visibleFilters (): FilterModel[] {
      return this.allVital || this.vitalOnly
        ? this.vitalFilters
        : this.filtersModel
    },

    allVital (): boolean {
      return this.filters.length === this.vitalFilters.length
    },

    vitalFilters (): FilterModel[] {
      const prices = this.filtersModel.filter(field => field.key === 'price')
      const booleans = this.filtersModel.filter(field => field.key === 'toggle')
      const others = this.filtersModel.filter(field => !['price', 'toggle'].includes(field.key))

      const othersCount = EXPANDED_MAX - (prices.length + booleans.length)

      return othersCount > 0
        ? [...prices, ...others.slice(0, othersCount - 1), ...booleans]
        : [...prices, ...booleans]
    }
  },

  watch: {
    filters: {
      deep: true,
      handler () {
        this.init()
        this.update++
      }
    },

    '$route.query': {
      handler () {
        this.init()
        this.update++
      },
    }
  },

  beforeMount () {
    this.init()
    this.update++
  },

  mounted () {
    this.update++
  },

  methods: {
    init () {
      const filters = createFilters(this.$route.query, this.definitions)

      this.$store.commit('catalog/filters', filters.map((f) => {
        return {
          identity: f.identity,
          key: f.key,
          min: f.min,
          max: f.max,
          values: f.values,
        }
      }))

      this.filtersModel = createFilterModel(
        this.filters,
        new Map(filters.map(f => [f.key, f]))
      )
    },

    async changeBoolean (filter: FilterModel, value: boolean) {
      filter.isActive = value
      await this.updateRoute()
    },

    async changeRangeMin (filter: FilterModel, value: string) {
      const definition = this.definitions.get(filter.key) as FilterDefinition

      const min = !isNaN(parseFloat(value)) ? Number(value) : null
      filter.min = min !== null ? Math.max(Math.abs(min), definition.min as number) : definition.min
      await this.updateRoute()
    },

    async changeRangeMax (filter: FilterModel, value: string) {
      const definition = this.definitions.get(filter.key) as FilterDefinition

      const max = !isNaN(parseFloat(value)) ? Number(value) : null
      filter.max = max !== null ? Math.min(Math.abs(max), definition.max as number) : definition.max
      await this.updateRoute()
    },

    async changeListValue (values: Record<string, boolean>, key: string, value: boolean) {
      values[key] = value
      await this.updateRoute()
    },

    async updateRoute () {
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...createQuery(this.filtersModel, this.definitions),
          page: this.$route.query.page,
          sort: this.$route.query.sort,
        },
      })
      this.update++
    },

    toggle (filter: FilterModel) {
      filter.expanded = !filter.expanded
      this.update++
    }
  }
})
