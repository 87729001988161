import { NuxtContext } from '@@/website/types/nuxt'
import { SortType, SortMethodsAvailable } from '@@/website/store/catalog'

export default function ({ store, query, redirect, route }: NuxtContext) {
  if (route.name === 'catalog-details') {
    redirect('/catalog')
  }

  const catalogFilterOpened = store.getters['modals/opened']('catalogFilters')
  const catalogTreeOpened = store.getters['modals/opened']('catalogTree')

  store.commit('gallery/close')
  store.commit('modals/closeAll')
  store.commit('modals/toggle', { name: 'catalogFilters', force: catalogFilterOpened })
  store.commit('modals/toggle', { name: 'catalogTree', force: catalogTreeOpened })

  const term = route.name === 'search' ? String(query.q) : ''
  const sort = SortMethodsAvailable[query.sort as SortType]
  const page = Math.max(Number(query.page), 1)

  store.commit('catalog/term', term)
  store.commit('catalog/sort', sort)
  store.commit('catalog/page', page)

  const redirectQuery = {
    ...route.query,
    ...(route.query?.sort ? { sort: route.query.sort } : {}),
    ...(route.query?.success ? { success: route.query.success } : {}),
    ...(term ? { q: term } : {}),
    ...(page > 1 ? { page: '' + page } : {})
  }

  if (JSON.stringify(route.query) !== JSON.stringify(redirectQuery)) {
    redirect({
      path: route.path,
      params: route.params,
      query: redirectQuery
    })
  }
}
