var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',_vm._g({class:{
        'sw-switch': true,
        'sw-switch_toggled': _vm.value,
        'sw-switch_disabled': _vm.disabled,
        'sw-switch_roundings_none': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.NONE,
        'sw-switch_roundings_small': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.SMALL,
        'sw-switch_roundings_large': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.LARGE,
    },attrs:{"tabindex":"0"}},_vm.$listeners),[_c('input',{staticClass:"sw-switch__input",attrs:{"name":_vm.name,"disabled":_vm.disabled,"type":"checkbox","tabindex":"-1"},domProps:{"checked":_vm.value}}),_vm._v(" "),_vm._m(0),_vm._v(" "),(_vm.label || _vm.$slots.default)?_c('span',{staticClass:"sw-switch__label"},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"sw-switch__core"},[_c('span',{staticClass:"sw-switch__button"})])}]

export { render, staticRenderFns }