import { GET_SETTINGS } from '@@/graphQL/web'

export default async (context) => {
  if (context.store.state.siteCode || !context.store.state.admin) {
    if (!context.store.state.settings?.system_language) {
      const { data } = await context.app.apolloProvider.defaultClient.query({
        query: GET_SETTINGS,
        variables: { siteCode: context.store.state.siteCode }
      })

      const language = data.settings?.system_language

      await context.app.i18n.setLocale(language.toLowerCase())
    } else if (typeof window !== 'undefined') {
      const language = window.__NUXT__?.config?.app?.LANGUAGE || context.store.state.settings?.system_language

      if (language) {
        await context.app.i18n.setLocale(language.toLowerCase())
      }
    }
  }
}
