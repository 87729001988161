<template>
    <SwModalWindow
        :class="$style['window']"
        :opened="opened"
        :width="width"
        @close="$emit('close')"
    >
        <h3 class="sw-title sw-title_mt0">
            <slot name="title" />
        </h3>

        <div :class="[$style['window__separator'], 'sw-separator']" />

        <slot />

        <template v-if="$slots.extra" #extra>
            <slot name="extra" />
        </template>
    </SwModalWindow>
</template>

<script>
export default {
  name: 'SimpleModalWindow',

  props: {
    opened: {
      type: Boolean,
      default: false
    },

    width: {
      type: Number,
      default: 352
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.window {
  padding: 16px 24px;
  @media screen and (max-width: @bp-mobile-small) {
    padding: 0;
  }

  &__separator:global(.sw-separator) {
    min-width: calc(100% + 24px * 2);
    border-color: #eef1f3;
    margin: 0 -24px 16px -24px !important;
  }
}
</style>
