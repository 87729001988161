<template>
    <SimpleModalWindow
        :class="$style['window']"
        :opened="$store.getters['modals/opened']('recoveryConfirm')"
        @close="close('recoveryConfirm')"
    >
        <template #title>
            {{ $t('auth_recovery') }}
        </template>

        <div :class="$style['window__description']">
            <div class="sw-text">
                {{ $t('auth_password_recovery_desc') }} <span>{{ email }}</span>
            </div>
        </div>

        <SwButton
            :class="$style['window__button']"
            wide
            @click="openLoginModal"
        >
            {{ $t('auth_back_to_login') }}
        </SwButton>
    </SimpleModalWindow>
</template>

<script>
import SimpleModalWindow from '@@/website/components/common/SimpleModalWindow'

export default {
  name: 'ModalPasswordRecoveryConfirm',

  components: {
    SimpleModalWindow
  },

  computed: {
    email () {
      return this.$store.getters['recovery/email']
    }
  },

  methods: {
    openLoginModal () {
      this.close('recoveryConfirm')
      this.open('login')
    },

    open (name) {
      this.$store.commit('modals/open', name)
    },

    close (name) {
      this.$store.commit('modals/close', name)
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.window {
  padding: 16px 24px;
  z-index: 100;
  @media screen and (max-width: @bp-mobile-small) {
    width: 100%;
    padding: 0;
  }

  :global(.sw-modal-window__container) {
    @media screen and (max-width: @bp-mobile-small) {
      height: 100%;
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }

  :global(.sw-separator) {
    min-width: calc(100% + 16px * 2);
    margin: 0 -16px 16px -16px !important;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    @media screen and (max-width: @bp-mobile-small) {
      margin-top: auto;
    }
  }

  &__button {
    @media screen and (max-width: @bp-mobile-small) {
      margin-bottom: auto;
    }
  }

  :global(.sw-panel) {
    padding: 16px 20px;
    @media screen and (max-width: @bp-mobile-small) {
      height: 100%;
      box-sizing: border-box;
      margin-bottom: 0;
    }
  }
}
</style>
