<template>
    <client-only>
        <PageError
            v-if="!$store.state.loading"
            :code="code || code === 0 ? code : error ? error.statusCode : 404"
        />
    </client-only>
</template>

<script>
import PageError from '@@/website/components/PageError'

export default {
  components: { PageError },

  layout: 'error',

  props: ['error', 'code'],

  head () {
    return {
      title: this.$t('admin_error')
    }
  },

  mounted () {
    const link = document.createElement('link')

    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = '/fonts.css'

    document.head.appendChild(link)
  }
}
</script>
