<template>
    <div class="sw-icon-button">
        <slot />
    </div>
</template>

<script>
export default {
  name: 'SwIconButton'
}
</script>

<style lang="less">
@import (reference) "./assets/Web.less";
.sw-icon-button {
  color: @black-title;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}
</style>
