<template>
    <SwModalSidebar
        :class="$style['sidebar']"
        :opened="$store.getters['modals/opened']('catalogTree')"
    >
        <SidebarHeader
            :class="$style['sidebar__header']"
            @close="close"
        >
            {{ $t('categories') }}
        </SidebarHeader>

        <div class="sw-separator" />

        <div :class="$style['sidebar__main']">
            <ClientOnly>
                <CatalogTree />
            </ClientOnly>
        </div>

        <footer :class="$style['sidebar__footer']">
            <SwButton
                :class="$style['sidebar__button']"
                :light="!isMobile"
                small
                @click="close"
            >
                {{ btnCloseTitle }}
            </SwButton>

            <CatalogFiltersResetter v-if="showResetButton">
                <template #default="{ reset }">
                    <SwButton
                        :class="$style['sidebar__button']"
                        borderless
                        small
                        transparent
                        inverted
                        @click="() => {reset(); close()}"
                    >
                        {{ $t('resetAll') }}
                    </SwButton>
                </template>
            </CatalogFiltersResetter>
        </footer>
    </SwModalSidebar>
</template>

<script>
import CatalogTree from '@@/website/components/common/CatalogTree'
import SidebarHeader from '@@/website/components/common/SidebarHeader'
import CatalogFiltersResetter from '@@/website/components/common/CatalogFiltersResetter'
import { mapState } from 'vuex'

export default {
  name: 'ModalCatalogTree',

  components: {
    CatalogTree,
    SidebarHeader,
    CatalogFiltersResetter,
  },

  data () {
    return {
      isMobile: false,
    }
  },

  computed: {
    ...mapState({
      totalProductsCount: state => state.catalog.totalProductsCount,
      hasCurrentCategoryId: state => !!state.catalog.currentCategoryId,
      hasFilters: state => !!state.catalog.filters.length,
      hasSort: state => state.catalog.sort?.id !== state.catalog.defaultSort
    }),

    showResetButton () {
      return this.hasCurrentCategoryId || this.hasFilters || this.hasSort
    },

    btnCloseTitle () {
      return this.isMobile ? `${this.$t('text_show')} ${this.totalProductsCount}` : this.$t('text_close')
    }
  },

  mounted () {
    const mobileMedia = window.matchMedia('(max-width: 768px)')
    const setMobile = () => {
      this.isMobile = mobileMedia.matches
    }

    setMobile()

    if (typeof mobileMedia?.addEventListener === 'function') {
      mobileMedia.addEventListener('change', setMobile)
      this.$once('hook:beforeDestroy', () => {
        mobileMedia.removeEventListener('change', setMobile)
      })
    }
  },

  methods: {
    close () {
      this.$store.commit('modals/close', 'catalogTree')
    },
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.sidebar {
  &__header {
    position: relative;
    background-color: #FFFFFF;
  }

  :global(.sw-separator) {
    display: none;
    margin-top: 0;
    margin-bottom: 0;

    @media screen and (max-width: @bp-mobile-small) {
      display: block;
    }
  }

  &__main {
    display: block;
    width: 246px;
    padding: 16px 12px 16px 8px;
    overflow-y: auto;

    @media screen and (max-width: @bp-tablet) {
      width: 100%;
    }

    @media screen and (max-width: @bp-mobile-small) {
      padding: 16px 8px;
      margin-bottom: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }

  &__button {
    @media screen and (max-width: @bp-mobile-small) {
      min-width: auto;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 20px;
    margin-top: auto;
    background-color: @grey-200;

    @media screen and (max-width: @bp-mobile-small) {
      padding: 16px;
    }
  }
}
</style>
