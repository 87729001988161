<template>
    <div
        :class="$style['notification']"
        :style="{
            fontFamily: `'${fontName}', -apple-system, BlinkMacSystemFont, sans-serif`,
        }"
    >
        <div :class="$style['notification__content']">
            <div :class="$style['notification__content-msg']">
                {{ msg }}
            </div>

            <div
                v-if="undo"
                :class="$style['notification__content-undo']"
                @click="undoAndClose"
            >
                {{ cancelText }}
            </div>
        </div>

        <div
            :class="$style['notification__close']"
            @click="$emit('close-toast')"
        >
            <svg
                :class="$style['notification__close-icon']"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                    d="M8.82152 9.75592L3.93099 4.86539C3.73573 4.67013 3.73573 4.35355 3.93099 4.15829L4.4024 3.68688C4.59766 3.49162 4.91424 3.49162 5.1095 3.68688L10 8.57741L14.8906 3.68688C15.0858 3.49162 15.4024 3.49162 15.5977 3.68688L16.0691 4.15829C16.2643 4.35355 16.2643 4.67013 16.0691 4.86539L11.1785 9.75592L16.0691 14.6464C16.2643 14.8417 16.2643 15.1583 16.0691 15.3535L15.5977 15.825C15.4024 16.0202 15.0858 16.0202 14.8906 15.825L10 10.9344L5.1095 15.825C4.91424 16.0202 4.59766 16.0202 4.4024 15.825L3.93099 15.3535C3.73573 15.1583 3.73573 14.8417 3.93099 14.6464L8.82152 9.75592Z"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ToastNotification',

  props: {
    msg: {
      type: [String, Error],
      default: ''
    },

    undo: {
      type: Boolean,
      default: false
    },

    cancelText: {
      type: String,
      default: ''
    },

    fontName: {
      type: String,
      default: ''
    }
  },

  methods: {
    undoAndClose () {
      this.$emit('undo')
      this.$emit('close-toast')
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.notification {
  display: flex;
  justify-content: space-between;

  &__content {
    padding-right: 12px;

    &-msg {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }

    &-undo {
      display: block;
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
      color: @brand-600;
      font-weight: normal;
      user-select: none;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__close {
    display: flex;
    cursor: pointer;

    &-icon {
      width: 20px;
      height: 20px;
      fill: @black-text;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

</style>

<style lang="less">
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "../../assets/Web.less";

@media only screen and (max-width: 600px) {
  .Vue-Toastification__container {
    padding: 0 16px 8px 16px;
  }
}

.Vue-Toastification__toast {
  background: white;
  color: @black-title;
  box-shadow: 0 8px 16px rgba(31, 31, 46, 0.12);
  padding: 16px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  min-height: 44px;
  min-width: auto;
  max-width: none;
  width: 360px;

  &_roundings_none { border-radius: 0; }
  &_roundings_small { border-radius: @spacing-xs; }
  &_roundings_large { border-radius: 12px; }

  &--error {
    border: 1px solid @red-500;
    color: @red-500;
  }

  &--success {
    border: 1px solid @green-500;
    color: @green-500;
  }

  &--info {
    border: 1px solid @grey-500;
    color: @black-text;
  }
}
</style>
