<script>

import { SortMethodsAvailable } from '@@/website/store/catalog'

function normalizeChildren (context, slotProps) {
  if (context.$scopedSlots.default) {
    return context.$scopedSlots.default(slotProps) || []
  }

  return context.$slots.default || []
}

export default {
  name: 'CatalogFiltersResetter',

  methods: {
    async reset () {
      this.$store.commit('catalog/sort', SortMethodsAvailable[this.$store.state.catalog.defaultSort])
      this.$store.commit('catalog/filters', [])

      await this.$router.push({
        path: '/catalog',
        query: {},
        params: { ...this.$route.params }
      })
    }
  },

  render (h) {
    const children = normalizeChildren(this, {
      reset: this.reset
    })

    return children.length <= 1 ? children[0] : h('div', { on: this.$listeners }, children)
  }
}
</script>
