import {
  GetterTree,
  MutationTree
} from 'vuex'
import { State as RootState } from '@@/website/store'

export type State = {
  catalogFilters: boolean;
  catalogTree: boolean;
  forbidden: boolean;
  login: boolean;
  mainMenu: boolean;
  profileMenu: boolean;
  publicDocument: boolean;
  recoveryConfirm: boolean;
  recoveryRequest: boolean;
  registration: boolean;
  search: boolean;
}

export const state = (): State => ({
  catalogFilters: false,
  catalogTree: false,
  forbidden: false,
  login: false,
  mainMenu: false,
  profileMenu: false,
  publicDocument: false,
  recoveryConfirm: false,
  recoveryRequest: false,
  registration: false,
  search: false,
})

export const getters: GetterTree<State, RootState> = {
  opened: (state: State) => (name: keyof State) => state[name]
}

export const mutations: MutationTree<State> = {
  open: (state: State, name: keyof State) => {
    state[name] = true
  },

  close: (state: State, name: keyof State) => {
    state[name] = false
  },

  closeAll: (state: State) => {
    for (const name in state) {
      if (Object.prototype.hasOwnProperty.call(state, name)) {
        state[name as keyof State] = false
      }
    }
  },

  toggle: (state: State, { name, force }: { name: keyof State, force: boolean }) => {
    state[name] = force
  },
}
