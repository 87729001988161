export enum CASING {
  INITIAL = 'INITIAL',
  UPPERCASE = 'UPPERCASE'
}

export enum ROUNDINGS {
  NONE = 'M',
  SMALL = 'S',
  LARGE = 'L'
}

export type Color = string
export type ColorGroup = {
  [key: number]: Color;
}
export type ColorPalette = {
  blue: ColorGroup;
  green: ColorGroup;
  red: ColorGroup;
  pink: ColorGroup;
  orange: ColorGroup;
  yellow: ColorGroup;
  purple: ColorGroup;
  turquoise: ColorGroup;
  black: ColorGroup;
  brown: ColorGroup;
}
export type ColorGetter = {
  get (name: keyof ColorPalette, brand: number): Color;
}

export type FontName = string

export type Settings = {
  buttonsTextCasing: CASING,
  colors: {
    main: keyof ColorPalette,
    background: string,
  }
  favicon: string,
  fonts: {
    headings: FontName;
    texts: FontName;
  }
  roundings: ROUNDINGS;
}
