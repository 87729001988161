import { onError } from 'apollo-link-error'
import { ServerParseError } from 'apollo-link-http-common'
import { NuxtApp } from '@@/website/types/nuxt'

export default (app: NuxtApp) => {
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors && graphQLErrors.length) {
      console.log('[GraphQL errors]:', graphQLErrors)
      graphQLErrors.forEach((error) => {
        // @ts-ignore
        const debugMessage = error.debugMessage
        if (debugMessage) {
          console.debug(debugMessage)
        }
      })
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`)
      try {
        JSON.parse((networkError as ServerParseError).bodyText) // проверка, что строка содержит json
      } catch (e) {
        // If not replace parsing error message with real one
        networkError.message = (networkError as ServerParseError).bodyText
      }

      app.$notifications?.error(networkError.toString())
    }
  })
}
