<script>
import Vue from 'vue'

export default {
  name: 'SwLink',

  props: {
    to: {
      type: [Object, String],
      default: null
    },

    href: {
      type: String,
      default: null
    },

    black: {
      type: Boolean,
      default: false
    },

    inverted: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  render (h) {
    const { to, href, disabled } = this
    const attrs = this.$attrs
    const data = {
      attrs,
      class: {
        'sw-link': true,
        'sw-link_black': this.black,
        'sw-link_inverted': this.inverted,
        'sw-link_disabled': this.disabled
      },
      on: this.$listeners
    }
    const children = this.$slots.default

    return disabled
      ? h('span', data, children)
      : to && 'NuxtLink' in Vue.options.components
        ? h('NuxtLink', { ...data, props: { to } }, children)
        : href
          ? h('a', { ...data, attrs: { ...attrs, href } }, children)
          : h('span', data, children)
  }
}
</script>
