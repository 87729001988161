<template>
    <div class="tree">
        <div
            v-if="parent"
            :class="$style['tree__node']"
            @click="$emit('selectCategory', parent.id)"
        >
            <SvgIconBack :class="$style['tree__back']" />
            <div class="sw-text">
                {{ $t('text_back') }}
            </div>
        </div>

        <ul :class="$style['tree__subtree']">
            <TreeSubtree
                v-for="(item, index) in parent.children"
                :key="index"
                :item="item"
                :selected-id="selectedId"
                @selectCategory="$emit('selectCategory', $event)"
                @closeModal="$emit('closeModal')"
            />
        </ul>
    </div>
</template>

<script>
import TreeSubtree from '@@/website/components/common/CatalogTree/TreeSubtree'
import SvgIconBack from '@@/framework/icons/back.svg'

export default {
  name: 'TreeInDepth',

  components: {
    TreeSubtree,
    SvgIconBack
  },

  props: {
    tree: {
      type: Array,
      default: () => []
    },

    selectedId: {
      type: Number,
      default: 0
    }
  },

  computed: {
    selectedItem () {
      return this.findNode(this.tree, this.selectedId)
    },

    parent () {
      if (this.selectedItem && this.selectedItem.parent !== null) {
        return this.findNode(this.tree, this.selectedItem.parent.id)
      }
      return undefined
    }
  },

  methods: {
    findNode (tree, id) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].id === id) {
          return tree[i]
        }
      }

      for (let j = 0; j < tree.length; j++) {
        const result = this.findNode(tree[j].children || [], id)
        if (result !== undefined) {
          return result
        }
      }

      return undefined
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.tree {
  &__node {
    display: flex;
    padding: 12px 0 12px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 0;

    @media screen and (max-width: @bp-mobile-small) {
      padding-left: 8px;
      padding-right: 8px;
    }

    &:hover {
      background: @grey-200;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  &__back {
    width: 24px;
    height: 24px;
    margin-right: @spacing-xxs;
    color: @black-text;
    flex-shrink: 0;
  }

  &__subtree {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}
</style>
