import * as pluralizationRules from './pluralization'

export default {
  fallbackLocale: 'en',
  numberFormats: {
    en: { currency: { style: 'currency', currency: 'USD' } },
    ru: { currency: { style: 'currency', currency: 'RUB' } },
    es: { currency: { style: 'currency', currency: 'EUR' } }
  },
  pluralizationRules,
}
