import { MutationTree } from 'vuex'

export type Address = {
  city: {
    id: number;
    name: string;
  },
  street: {
    id: number;
    name: string;
  },
  building: string;
  flat: string;
}

export enum GENDER {
  FEMALE = 'female',
  MALE = 'male'
}

export type State = {
  firstName: string|null;
  lastName: string|null;
  gender: GENDER|null;
  birthday: string|null;
  email: string|null;
  phone: string|null;
  address: Address;
  bonuses: number;
  authenticated: boolean;
}

export const state = (): State => ({
  firstName: null,
  lastName: null,
  gender: null,
  birthday: null,
  email: null,
  phone: null,
  address: {
    city: { id: 0, name: '' },
    street: { id: 0, name: '' },
    building: '',
    flat: '',
  },
  bonuses: 0,
  authenticated: false,
})

export const mutations: MutationTree<State> = {
  set (state: State, values: Partial<State>) {
    state.firstName = values.firstName ?? state.firstName
    state.lastName = values.lastName ?? state.lastName
    state.gender = values.gender ?? state.gender
    state.birthday = values.birthday ?? state.birthday
    state.email = values.email ?? state.email
    state.phone = values.phone ?? state.phone
    state.address = values.address ?? state.address
    state.bonuses = values.bonuses ?? state.bonuses
    state.authenticated = values.authenticated ?? state.authenticated
  },

  reset (state: State) {
    state.firstName = null
    state.lastName = null
    state.gender = null
    state.birthday = null
    state.email = null
    state.phone = null
    state.address = {
      city: { id: 0, name: '' },
      street: { id: 0, name: '' },
      building: '',
      flat: '',
    }
    state.bonuses = 0
    state.authenticated = false
  },
}
