<template>
    <div :class="$style['header']">
        <slot
            :class="$style['header__icon']"
            name="icon"
        />

        <h3
            :class="[
                $style['header__title'],
                'sw-title',
                'sw-title_m0',
            ]"
        >
            <slot />
        </h3>

        <div
            :class="$style['header__close']"
            @click="$emit('close')"
        >
            <SwIconButton>
                <SvgIconCross />
            </SwIconButton>
        </div>
    </div>
</template>

<script>
import SvgIconCross from '@@/framework/icons/cross.svg'

export default {
  name: 'SidebarHeader',

  components: {
    SvgIconCross
  },

  mounted () {
    /** @param {KeyboardEvent} event */
    const closeOnEsc = (event) => {
      if (event.key === 'Escape') {
        this.$emit('close')
      }
    }

    document.addEventListener('keydown', closeOnEsc)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', closeOnEsc)
    })
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 24px;
  @media screen and (max-width: @bp-mobile-small) {
    min-height: 4rem;
    padding: 0 16px;
    margin: 0;
  }

  &__icon {
    width: fit-content;
  }

  &__title {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    @media screen and (max-width: @bp-mobile-small) {
      justify-content: center;
    }
  }

  &__close {
    margin-right: 9px;
    @media screen and (max-width: @bp-mobile-small) {
      margin-right: 5px;
    }
  }
}
</style>
