<template>
    <div ref="root" class="sw-datepicker">
        <SwInput
            :name="name"
            :label="label"
            :value="formatted"
            :disabled="disabled"
            autocomplete="off"
            readonly
            @focus="expanded = true"
            @keyup="onInputKeyup"
        >
            <template #icon>
                <SvgIconCalendar class="sw-datepicker__icon" />
            </template>
        </SwInput>

        <SwTransition name="fade-2">
            <SwCalendar
                v-if="expanded"
                :value="parsed"
                :nullable="nullable"
                class="sw-datepicker__calendar"
                @input="$emit('input', $event)"
            />
        </SwTransition>
    </div>
</template>

<script>
import SvgIconCalendar from '@@/framework/icons/calendar.svg'
import SwCalendar from '@@/framework/components/SwCalendar'
import SwInput from '@@/framework/components/SwInput'
import SwTransition from '@@/framework/components/SwTransition'

const KEYS = {
  BACKSPACE: 8,
  DELETE: 46,
  ENTER: 13,
  ESCAPE: 27
}

export default {
  name: 'SwDatepicker',

  components: {
    SvgIconCalendar,
    SwCalendar,
    SwInput,
    SwTransition
  },

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    name: {
      type: String,
      default: 'datepicker'
    },

    value: {
      type: [Date, String],
      default: null
    },

    label: {
      type: String,
      default: ''
    },

    nullable: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    expanded: false
  }),

  computed: {
    format () {
      return {
        en: 'd/m/Y',
        es: 'd.m.Y',
        ru: 'd.m.Y'
      }[this.locale]
    },

    formatted () {
      const date = this.parsed

      return date
        ? this.format
            .replace(/d/, ('0' + (date.getDate())).slice(-2))
            .replace(/m/, ('0' + (date.getMonth() + 1)).slice(-2))
            .replace(/Y/, date.getFullYear())
        : null
    },

    locale () {
      return this.$i18n ? this.$i18n.locale : 'ru-RU'
    },

    /** @returns {Date|null} */
    parsed () {
      if (typeof this.value === 'string') {
        const parsed = new Date(this.value)
        return isNaN(parsed.valueOf()) ? null : parsed
      }

      if (typeof this.value === 'object' && !isNaN(Date.parse(this.value))) {
        return this.value
      }

      return null
    }
  },

  mounted () {
    /** @param {MouseEvent} event */
    const onMissClick = (event) => {
      if (!this.$refs.root || !this.$refs.root.contains(event.target)) {
        this.expanded = false
      }
    }

    document.addEventListener('click', onMissClick)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onMissClick)
    })
  },

  methods: {
    /** @param {KeyboardEvent} event */
    onInputKeyup (event) {
      if ([KEYS.ENTER, KEYS.ESCAPE].includes(event.keyCode)) {
        this.expanded = false
      }

      if ([KEYS.BACKSPACE, KEYS.DELETE].includes(event.keyCode)) {
        this.$emit('input', null)
      }
    }
  }
}
</script>

<style lang="less">
@import (reference) "./assets/Web.less";
.sw-datepicker {
  position: relative;

  &__icon {
    color: #AFB9C3;
  }

  &__calendar {
    border: none;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(31, 31, 46, 0.16);
    position: absolute;
    z-index: 1005;
  }
}
</style>
