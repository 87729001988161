// @ts-ignore
import uniqid from 'uniqid'
import { setContext } from 'apollo-link-context'
import { NuxtApp } from '@@/website/types/nuxt'

export default (app: NuxtApp) => setContext((_, { headers }) => {
  if (!app.$cookies.get('X-IDENTITY')) {
    app.$cookies.set('X-IDENTITY', `${uniqid()}-${uniqid()}`)
  }

  let SIMLA_CORE_TOKEN = {}
  let LANGUAGE = {}

  if ('admin' in app.store.state && typeof window !== 'undefined') {
    // TODO: это нужно для песочницы, запускаемой через yarn dev:admin, нужно подумать как прокинуть этот токен иначе
    // были проблемы с попаданием в витрину
    // @ts-ignore
    SIMLA_CORE_TOKEN = { 'SIMLA-CORE-TOKEN': window.__NUXT__?.config?.app?.SIMLA_CORE_TOKEN || 'SIMLA_CORE_TOKEN_DEV' }

    // @ts-ignore
    const lang = window.__NUXT__?.config?.app?.LANGUAGE
    if (lang) {
      LANGUAGE = { 'X-LANGUAGE': lang }
    }
  }

  return {
    headers: {
      ...headers,
      ...SIMLA_CORE_TOKEN,
      ...LANGUAGE,
      'X-IDENTITY': app.$cookies.get('X-IDENTITY')
    }
  }
})
