<template>
    <transition :name="`sw-transition-${name}`" v-on="$listeners">
        <slot />
    </transition>
</template>

<script>
export default {
  name: 'SwTransition',

  props: {
    name: {
      type: String,
      default: 'fade'
    }
  }
}
</script>

<style lang="less">
@import (reference) "./assets/Web.less";

.sw-transition-fade-enter-active,
.sw-transition-fade-leave-active {
  transition: all 0.5s ease;
}
.sw-transition-fade-enter,
.sw-transition-fade-leave-to {
  opacity: 0;
}

.sw-transition-fade-2-enter-active,
.sw-transition-fade-2-leave-active {
  transition: all 0.35s ease 0s;
  opacity: 1;
}
.sw-transition-fade-2-enter,
.sw-transition-fade-2-leave-to {
  opacity: 0;
}

.sw-transition-fade-3-enter-active {
  transition: all 0.15s ease 0s;
  opacity: 1;
}
.sw-transition-fade-3-leave-active {
  transition: all 0.25s ease 0s;
  opacity: 1;
}
.sw-transition-fade-3-enter,
.sw-transition-fade-3-leave-to {
  opacity: 0;
}

/* slide */
.sw-transition-slide-enter-active {
  transition: all 0.5s ease;
}
.sw-transition-slide-leave-active {
  transition: all 0.5s ease;
}
.sw-transition-slide-enter,
.sw-transition-slide-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

/* slide-up */
.sw-transition-slide-up-enter-active {
  transition: all 0.25s ease 0.1s;
}
.sw-transition-slide-up-leave-active {
  transition: all 0.25s;
}
.sw-transition-slide-up-enter,
.sw-transition-slide-up-leave-to {
  opacity: 0;
  transform: translateY(-70px);
}

/* slide-right */
.sw-transition-slide-right-enter-active { transition: all 0.25s linear 0.1s; }
.sw-transition-slide-right-leave-active { transition: all 0.25s; }
.sw-transition-slide-right-enter,
.sw-transition-slide-right-leave-to {
  transform: translateX(100%);
}

/* slide-left */
.sw-transition-slide-left-enter-active { transition: all 0.25s linear 0.1s; }
.sw-transition-slide-left-leave-active { transition: all 0.25s; }
.sw-transition-slide-left-enter,
.sw-transition-slide-left-leave-to {
  transform: translateX(-100%);
}

/* collapse */
.sw-transition-collapse-enter-active {
  transform: scaleY(1);
  transform-origin: 50% 0;
  transition: all 0.05s linear;
}
.sw-transition-collapse-leave-active {
  transform: scaleY(1);
  transform-origin: 50% 0;
  transition: all 0.05s linear;
}
.sw-transition-collapse-enter,
.sw-transition-collapse-leave-to {
  opacity: 0;
  overflow: hidden;
  transform: scaleY(0);
}

/** zoom */
.sw-transition-zoom-enter-active {
  transition: .25s cubic-bezier(0.22, 0.71, 0.26, 0.95) 0s;
  transform-origin: center center;
  transform: scale(1);
  opacity: 1;
}
.sw-transition-zoom-leave-active {
  transition: .3s ease 0s;
  transform: scale(0.8);
  opacity: 0;
}

.sw-transition-zoom-enter,
.sw-transition-zoom-leave-to {
  transform: scale(0.7);
}
</style>
