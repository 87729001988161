import { render, staticRenderFns } from "./SwInputDropdown.vue?vue&type=template&id=5225e16c"
import script from "./SwInputDropdown.vue?vue&type=script&lang=js"
export * from "./SwInputDropdown.vue?vue&type=script&lang=js"
import style0 from "./SwInputDropdown.vue?vue&type=style&index=0&id=5225e16c&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports