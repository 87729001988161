import {
  GetterTree,
  MutationTree
} from 'vuex'
import { State as RootState } from './index'

export type State = {
  images: string[];
  index: number;
  opened: boolean;
}

export const state = (): State => ({
  images: [],
  index: 0,
  opened: false
})

export const getters: GetterTree<State, RootState> = {
  images: (state: State) => state.images,
  index: (state: State) => state.index,
  opened: (state: State) => state.opened,
}

export const mutations: MutationTree<State> = {
  open (state: State, { images, index }: {
    images?: string[];
    index?: number;
  }) {
    state.images = images ?? []
    state.index = index ?? 0
    state.opened = true
  },

  close (state: State) {
    state.opened = false
  },

  moveTo: (state: State, index: number) => {
    state.index = index
  }
}
