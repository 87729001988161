<template>
    <SwImagePlaceholder
        v-if="showPlaceholder"
        :style="{
            maxWidth: `${width}px`,
            maxHeight: `${height}px`,
        }"
        :title="alt"
    />

    <img
        v-else-if="image.src"
        v-image-fallback
        :src="image.src"
        :srcSet="image.srcSet"
        :alt="alt"
        :width="width"
        :height="height"
        :class="{
            'sw-image': true,
            'sw-image_roundings_none': $simlaweb.settings.roundings === ROUNDINGS.NONE,
            'sw-image_roundings_small': $simlaweb.settings.roundings === ROUNDINGS.SMALL,
            'sw-image_roundings_large': $simlaweb.settings.roundings === ROUNDINGS.LARGE
        }"
        :title="alt"
        v-on="$listeners"
    >
</template>

<script>
import SwImagePlaceholder from '@@/framework/components/SwImagePlaceholder'
import { ROUNDINGS } from '@@/framework/types'

const PROCESSING = {
  NONE: 'none',
  CROP: 'crop',
  RESIZE: 'resize'
}

const FALLBACK_IMAGE_WORKERS = [
  'https://worker1.retailcrm.tech',
]

export default {
  name: 'SwImage',

  directives: {
    imageFallback: {
      /**
       * @param {HTMLImageElement} el
       * @param _
       * @param {VNode} vNode
       */
      bind (el, _, vNode) {
        if (!vNode.context.src) { return }
        if (vNode.context.processing !== PROCESSING.NONE) {
          try {
            const image = new Image()
            const original = el.src

            image.src = original
            el.src = vNode.context.getProcessedImageUrl(image.width, image.height)

            image.onload = ({ path }) => {
              el.src = original
              if (path && path[0].height === 1) { // if 404 responses 1x1 image
                vNode.context.loadedWithErrors = true
              }
            }

            image.onerror = () => {
              if (vNode.context.$config.dev) {
                el.src = vNode.context.src
              } else {
                vNode.context.loadedWithErrors = true
              }
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
          }
        }
      }
    }
  },

  components: {
    SwImagePlaceholder
  },

  props: {
    src: {
      type: [String, Boolean],
      default: ''
    },

    alt: {
      type: String,
      default: 'Image'
    },

    width: {
      type: [String, Number],
      default: 492
    },

    height: {
      type: [String, Number],
      default: 400
    },

    processing: {
      type: String,
      default: PROCESSING.RESIZE
    }
  },

  data: () => ({
    loadedWithErrors: false
  }),

  computed: {
    ROUNDINGS: () => ROUNDINGS,

    image () {
      const parse = value => parseInt(value) ? parseInt(value) * 2 : value

      return {
        src: this.src
          ? this.processing !== PROCESSING.NONE ? this.getProcessedImageUrl(this.width, this.height) : this.src
          : null,
        srcSet: this.src && this.processing !== PROCESSING.NONE
          ? this.getProcessedImageUrl(parse(this.width), parse(this.height)) + ' ' + '2x'
          : null
      }
    },

    showPlaceholder () {
      return this.loadedWithErrors || !this.src
    }
  },

  methods: {
    getProcessedImageUrl (width, height) {
      switch (this.processing) {
        case PROCESSING.CROP:
          return this.urlToCropped(this.src, width, height)

        case PROCESSING.RESIZE:
        default:
          return this.urlToResized(this.src, width, height)
      }
    },

    urlToCropped (src, width, height) {
      return this.urlToProcessed(src, 'c', width, height)
    },

    urlToResized (src, width, height) {
      return this.urlToProcessed(src, 'r', width, height)
    },

    urlToProcessed (src, operation, width, height) {
      const workers = this.$config.imageWorkers?.split(',') || FALLBACK_IMAGE_WORKERS

      return `${workers[Math.floor(Math.random() * workers.length)]}/${operation}/${width}x${height}/${src}`
    }
  }
}
</script>

<style lang="less">
@import (reference) "./assets/Web.less";

.sw-image {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  box-sizing: border-box;

  &_roundings_none { border-radius: 0; }
  &_roundings_small { border-radius: 8px; }
  &_roundings_large { border-radius: 16px; }
}
</style>
