<template>
    <label
        :class="{
            'sw-switch': true,
            'sw-switch_toggled': value,
            'sw-switch_disabled': disabled,
            'sw-switch_roundings_none': $simlaweb.settings.roundings === ROUNDINGS.NONE,
            'sw-switch_roundings_small': $simlaweb.settings.roundings === ROUNDINGS.SMALL,
            'sw-switch_roundings_large': $simlaweb.settings.roundings === ROUNDINGS.LARGE,
        }"
        tabindex="0"
        v-on="$listeners"
    >
        <input
            :name="name"
            :checked="value"
            :disabled="disabled"
            type="checkbox"
            class="sw-switch__input"
            tabindex="-1"
        >

        <span class="sw-switch__core">
            <span class="sw-switch__button" />
        </span>

        <span v-if="label || $slots.default" class="sw-switch__label">
            <slot>{{ label }}</slot>
        </span>
    </label>
</template>

<script>
import { ROUNDINGS } from '@@/framework/types'

export default {
  name: 'SwSwitch',

  props: {
    name: {
      type: String,
      default: ''
    },

    value: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ROUNDINGS: () => ROUNDINGS
  }
}
</script>

<style lang="less">
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.sw-switch {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  user-select: none;
  position: relative;

  &_disabled { pointer-events: none; }

  &__input {
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    overflow: hidden;
    margin: -1px;
    position: absolute;
  }

  &__label {
    margin-left: 12px;
    font-size: 16px;
    line-height: 1.5;
  }

  &_disabled &__label {
    color: @grey-800;
  }

  &__core {
    display: block;
    width: 40px;
    height: 24px;
    box-sizing: border-box;
    background-color: @grey-500;
    border-radius: 6px;
    outline: none;
    transition: background-color 0.3s;
    user-select: none;
    margin: 0;
    position: relative;

    &:hover {
      background-color: var(--sw-color-main-100);
      border: 1px solid var(--sw-color-main-500);
    }
  }

  &_disabled &__core {
    background-color: @grey-600;
  }

  &_toggled &__core {
    background-color: var(--sw-color-main-500);
    &:hover {
      background-color: var(--sw-color-main-700);
      border: none;
    }
  }

  &_disabled&_toggled &__core {
    background-color: var(--sw-color-main-300);
  }

  &__button {
    display: block;
    width: 16px;
    height: 16px;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 2;
  }

  &__core:hover &__button {
    background-color: var(--sw-color-main-200);
    top: 3px;
    left: 3px;
  }

  &_toggled &__core:hover &__button {
    background-color: #FFFFFF;
    top: 4px;
    left: 20px;
  }

  &_toggled &__button { left: 20px; }

  &_roundings_none &__core { border-radius: 0; }
  &_roundings_small &__core { border-radius: 6px; }
  &_roundings_large &__core { border-radius: 20px; }

  &_roundings_none &__button { border-radius: 0; }
  &_roundings_small &__button { border-radius: 4px; }
  &_roundings_large &__button { border-radius: 8px; }
}
</style>
