<template>
    <div
        :class="{
            'sw-panel': true,
            [`sw-panel_elevation_z${elevation}`]: true,
            'sw-panel_fluid': fluid,
            'sw-panel_roundings_none': $simlaweb.settings.roundings === ROUNDINGS.NONE,
            'sw-panel_roundings_small': $simlaweb.settings.roundings === ROUNDINGS.SMALL,
            'sw-panel_roundings_large': $simlaweb.settings.roundings === ROUNDINGS.LARGE,
            'sw-panel_transparent': transparent,
        }"
    >
        <h3 v-if="$slots.title" class="sw-title sw-title_mt0">
            <slot name="title" />
        </h3>

        <slot />
    </div>
</template>

<script>
import { ROUNDINGS } from '@@/framework/types'

export default {
  name: 'SwPanel',

  props: {
    elevation: {
      type: [Number, String],
      default: 1
    },

    fluid: {
      type: Boolean,
      default: false
    },

    transparent: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ROUNDINGS: () => ROUNDINGS
  }
}
</script>

<style lang="less">
@import (reference) "./assets/Web.less";

.sw-panel {
  display: flex;
  flex-direction: column;
  padding: 40px / 2 16px;
  box-sizing: border-box;
  background: #FFFFFF;
  margin-bottom: 16px;

  @media screen and (min-width: @bp-mobile-big) {
    padding: 32px 24px;
  }

  &_fluid { width: 100%; }

  &_roundings_none { border-radius: 0; }
  &_roundings_small { border-radius: 8px; }
  &_roundings_large { border-radius: 16px; }

  &_transparent { background: transparent; }

  &_elevation_z0 { box-shadow: 0 0 0 rgba(0, 0, 0, 0); }
  &_elevation_z1 { box-shadow: 0 2px 4px rgba(31, 31, 46, 0.16); }
}
</style>
