<template>
    <div
        :class="{
            'sw-container': true,
            'sw-container_fluid': fluid,
            'sw-container_stretched': stretched,
            'sw-container_padding_s': paddingY.toUpperCase() === 'S',
            'sw-container_padding_m': paddingY.toUpperCase() === 'M',
            'sw-container_padding_l': paddingY.toUpperCase() === 'L',
            'sw-container_padding_all-0': pa0,
            'sw-container_padding_y-0': py0,
            'sw-container_has-background-image': !!bgImage
        }"
        :style="{
            backgroundImage: bgImage ? `url(${bgImage})` : 'none',
        }"
    >
        <div class="sw-container__content">
            <slot />
        </div>

        <div
            v-if="bgColor"
            :style="{ background: hexToRgba(bgColor, parseFloat(bgOpacity)) }"
            class="sw-container__overlay"
        />
    </div>
</template>

<script>
export default {
  name: 'SwContainer',

  props: {
    /**
     * Растягивание по ширине родительского контейнера
     */
    fluid: {
      type: Boolean,
      default: false
    },

    /**
     * Растягивание на высоту текущей области просмотра
     */
    stretched: {
      type: Boolean,
      default: false
    },

    pa0: {
      type: Boolean,
      default: false
    },

    py0: {
      type: Boolean,
      default: false
    },

    paddingY: {
      type: String,
      default: 's'
    },

    bgColor: {
      type: String,
      default: ''
    },

    bgImage: {
      type: String,
      default: ''
    },

    bgOpacity: {
      type: String,
      default: '0%'
    }
  },

  methods: {
    hexToRgba (hex, opacity) {
      hex = hex.replace('#', '')

      const r = parseInt(hex.substring(0, 2), 16)
      const g = parseInt(hex.substring(2, 4), 16)
      const b = parseInt(hex.substring(4, 6), 16)
      const a = opacity / 100

      return `rgba( ${r}, ${g}, ${b}, ${a} )`
    }
  }
}
</script>

<style lang="less">
@import (reference) "./assets/Web.less";

.sw-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .padding-container;
  box-sizing: border-box;
  position: relative;

  &__content {
    z-index: 3;
    width: 100%;
    max-width: 1056px;
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &_padding_s { .padding-y-s }
  &_padding_m { .padding-y-m }
  &_padding_l { .padding-y-l }

  &_padding_all-0 {
    padding: 0;
  }

  &_padding_y-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  &_fluid {
    max-width: 100%;
    min-width: 100%;
  }

  &_stretched {
    min-height: 100vh;
  }

  &_center {
    text-align: center;
    align-items: center;
  }

  &_has-background-image {
    height: inherit;
    background-size: cover;
    background-position: center;
  }
}
</style>
