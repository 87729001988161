/* eslint-disable camelcase */
import gql from 'graphql-tag'
import {
  ValidationObserver,
  ValidationProvider,
  extend
} from 'vee-validate'
import {
  alpha_spaces,
  email,
  min,
  required
} from 'vee-validate/dist/rules'
import Vue from 'vue'

const EMAIL_VALIDATION_QUERY = gql`
  query validateEmail($email: String!) {
    result: validateEmail(email: $email) {
      valid: success
    }
  }
`

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

export default ({ app }) => {
  extend('alpha_spaces', {
    ...alpha_spaces,
    message () {
      return app.i18n.t('validation.alpha_spaces')
    }
  })
  extend('email', {
    ...email,
    message () {
      return app.i18n.t('validation.email')
    }
  })
  extend('email_acceptable', {
    message () {
      return app.i18n.t('validation.email_unacceptable')
    },

    async validate (email) {
      try {
        const { data } = await app.apolloProvider.defaultClient.query({
          query: EMAIL_VALIDATION_QUERY,
          variables: { email }
        })

        return { valid: data.result.valid }
      } catch (e) {
        console.error(e)
        return { valid: false }
      }
    }
  })
  extend('min', {
    ...min,
    message (_, { length }) {
      return app.i18n.tc('validation.min', length, { length })
    }
  })
  extend('required', {
    ...required,
    message () {
      return app.i18n.t('validation.required')
    }
  })
}
