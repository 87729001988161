<template>
    <div
        :class="{
            'sw-image-placeholder': true,
            'sw-image-placeholder_wide': wide,
            'sw-image-placeholder_roundings_none': $simlaweb.settings.roundings === ROUNDINGS.NONE,
            'sw-image-placeholder_roundings_small': $simlaweb.settings.roundings === ROUNDINGS.SMALL,
            'sw-image-placeholder_roundings_large': $simlaweb.settings.roundings === ROUNDINGS.LARGE
        }"
    >
        <div class="sw-image-placeholder__inner">
            <SvgSpritePicture />
        </div>
    </div>
</template>

<script>
import SvgSpritePicture from './sprite-picture.svg'
import { ROUNDINGS } from '@@/framework/types'

export default {
  name: 'SwImagePlaceholder',

  components: {
    SvgSpritePicture
  },

  props: {
    height: {
      type: [String, Number],
      default: 'auto'
    },

    wide: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ROUNDINGS: () => ROUNDINGS
  }
}
</script>

<style lang="less">
@import (reference) "./assets/Web.less";

.sw-image-placeholder {
  width: 100%;
  height: 100%;
  padding: 12px;
  background: #eef1f3;

  &_wide { width: 100% !important; }

  &_roundings_none { border-radius: 0; }
  &_roundings_small { border-radius: 8px; }
  &_roundings_large { border-radius: 16px; }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 100%;
    border: 1px solid #dee2e6b5;
    box-sizing: border-box;
  }

  &_roundings_none &__inner { border-radius: 0; }
  &_roundings_small &__inner { border-radius: 6px; }
  &_roundings_large &__inner { border-radius: 12px; }
}
</style>
