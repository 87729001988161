import gql from 'graphql-tag'

export const GET_PAGE = gql`
  query publicPage($url: String!, $siteCode: String, $unpublished: Boolean = false) {
    publicPage(url: $url, siteCode: $siteCode, unpublished: $unpublished) {
      id
      key
      url
      configs
      publicPageBlocks {
        id
        key
        name
        position
        configs
        pageBlockStyleKey
        pivotId
      }
    }
  }
`

export const GET_PAGE_BY_KEY = gql`
  query publicPageByKey($key: String!, $siteCode: String, $unpublished: Boolean = false) {
    publicPageByKey(key: $key, siteCode: $siteCode, unpublished: $unpublished) {
      id
      key
      url
      configs
      publicPageBlocks {
        configs
        id
        key
        name
        pageBlockStyleKey
        pivotId
        position
      }
    }
  }
`

export const GET_CATALOG_FILTERS = gql`
  query filters($siteCode: String, $slug: String) {
    filters(siteCode: $siteCode, slug: $slug) {
      identity
      key
      max
      min
      name
      type
      values
    }
  }
`

export const GET_PRODUCT_BREADCRUMBS = gql`
  query breadcrumbsProduct($product_group_slug: String, $slug: String!) {
    breadcrumbsProduct(product_group_slug: $product_group_slug, slug: $slug) {
      name
      slug
    }
  }
`

export const GET_BREADCRUMBS = gql`
  query breadcrumbsCatalog($slug: String!) {
    breadcrumbsCatalog(slug: $slug) {
      name
      slug
    }
  }
`

export const PRODUCT_INFO = gql`
  query productBySlug($siteCode: String, $slug: String) {
    productBySlug(siteCode: $siteCode, slug: $slug) {
      id
      article
      type
      url
      stock
      slug
      quantity
      name
      description
      labels
      brand
      offers {
        images
        price
        id
        quantity
        name
        weight
        boxLength: length
        boxWidth: width
        boxHeight: height
        properties {
          id
          name
          value
          code
          variative
        }
      }
    }
  }
`

export const USER_INFO = gql`
  query {
    info: me {
      address {
        building
        city
        flat
        cityId
        housing
        house
        id
        street
        streetId
      }
      info {
        bonuses
      }
      phone
      lastName
      gender
      firstName
      email
      birthday
    }
  }
`

export const GET_CATALOG_PRODUCTS = gql`
  query products(
    $filters: [FilterInput!]
    $page: Int
    $order: String
    $sort: String
    $product_group_slug: String
    $search: String
    $limit: Int
    $siteCode: String
  ) {
    products(
      filters: $filters
      page: $page
      order: $order
      sort: $sort
      product_group_slug: $product_group_slug
      search: $search
      limit: $limit
      siteCode: $siteCode
    ) {
      edges {
        name
        article
        type
        imageUrl
        slug
        labels
        offers {
          id
          name
          quantity
          price
        }
      }
      currentPage
      limit
      totalCount
      totalPageCount
    }
  }
`

export const GET_PRODUCTS_GOODS = gql`
  query productGoods($labels: [String] = [], $limit: Int = 16, $pivot_id: Int!, $siteCode: String) {
    productGoods(labels: $labels, limit: $limit, pivot_id: $pivot_id, siteCode: $siteCode) {
      id
      name
      article
      type
      slug
      url
      imageUrl
      offers {
        id
        name
        quantity
        purchasePrice
        price
        prices {
          offer {
            id
            name
            quantity
            purchasePrice
            price
            images
            active
          }
          price
          ordering
        }
        images
        active
        cartItem {
          id
          offer {
            id
            name
            quantity
            purchasePrice
            price
            images
            active
          }
        }
      }
      popular
      stock
      novelty
      recommended
      labels
    }
  }
`

export const GET_MENU = gql`
  query menuItems($siteCode: String, $mock: Boolean = false) {
    menuItems: menu(siteCode: $siteCode, mock: $mock) {
      name
      url
      position
    }
  }
`

export const GET_FOOTER_MENU = gql`
  query footerMenu($siteCode: String, $mock: Boolean = false) {
    footerMenu(siteCode: $siteCode, mock: $mock) {
      name
      url
      position
    }
  }
`

export const GET_CART_ITEMS = gql`
  query cartItems($mock: Boolean = false, $siteCode: String) {
    cartItems(mock: $mock, siteCode: $siteCode) {
      id
      offer {
        id
        name
        price
        product {
          type
        }
      }
      isQuantityAvailable
      images
      quantity
      currentQuantity
      canBuy
      isAvailable
      name
      slug
    }
  }
`

export const GET_ORDER_TOTAL = gql`
  query orderTotalPrice($code: String, $bonuses: Float, $mock: Boolean = false, $siteCode: String) {
    orderTotalPrice(code: $code, bonuses: $bonuses, mock: $mock, siteCode: $siteCode) {
      countOffer
      deliveryPrice
      offerPrice
      totalPrice
      creditBonuses
      discount
    }
  }
`

export const GET_PAYMENTS_INFO = gql`
  query orderPayments($siteCode: String) {
    orderPayments(siteCode: $siteCode) {
      active
      code
      description
      name
    }
  }
`
export const GET_DELIVERIES_INFO = gql`
  query orderDeliveries($siteCode: String) {
    orderDeliveries(siteCode: $siteCode) {
      active
      code
      defaultCost
      deliveryServices {
        active
        code
        name
      }
      description
      name
      paymentTypes {
        active
        code
        description
        name
      }
    }
  }
`

export const GET_SETTINGS = gql`
  query settings($siteCode: String) {
    settings(siteCode: $siteCode) {
      can_buy_without_authorization
      can_comment_checkout
      can_duplicate_links_from_the_header
      can_scrolling_header
      can_show_characteristic
      default_currency
      default_sorting
      hidden_product_in_not_stock
      required_checkout_birthday_authorization
      required_checkout_birthday_without_authorization
      required_checkout_email_authorization
      required_checkout_email_without_authorization
      required_checkout_firstname_authorization
      required_checkout_lastname_authorization
      required_checkout_name_without_authorization
      required_checkout_phone_authorization
      required_checkout_phone_without_authorization
      show_window_after_buy
      system_language
      timezone
      codeBeforeEndHead
      codeBeforeEndBody
      codeBeforeEndHeadMeta
      codeBeforeEndBodyMeta
      isPublished
      isDemoShop
      ingressAddress
      favicon
      copyrightLogo
    }
    designSettings(siteCode: $siteCode) {
      borderRadius
      buttonTextUpperCase
      favicon
      headingFont
      mainColor
      textFont
      backgroundColor
    }
  }
`

export const GET_META = gql`
  query metaBySlug($route: String!, $siteCode: String, $slug: String = "") {
    metaBySlug(route: $route, slug: $slug, siteCode: $siteCode) {
      alt
      description
      title
      ogTitle
      ogDescription
      ogImage
      ogUrl
    }
  }
`

export const GET_PRODUCT_GROUPS = gql`
  query productGroupsTree($siteCode: String) {
    productGroupsTree(siteCode: $siteCode) {
      id
      name
      slug
      depth
      parent {
        id
      }
      children {
        children {
          id
          children {
            id
            name
            slug
            depth
            parent {
              id
            }
          }
          id
          name
          slug
          depth
          parent {
            id
          }
        }
        id
        name
        slug
        depth
        parent {
          id
        }
      }
    }
  }
`

export const GET_ORDERS = gql`
  query historyOrders($limit: Int, $page: Int) {
    historyOrders(limit: $limit, page: $page) {
      currentPage
      limit
      totalCount
      totalPageCount
      edges {
        id
        number
        addressBuilding
        addressCity
        addressCityId
        addressHouse
        addressHousing
        images
        lastName
        paymentName
        paymentStatusName
        phone
        statusUpdatedAt
        summ
        totalSumm
        status {
          name
        }
        statusGroup {
          active
          code
          name
        }
        addressFlat
        addressStreetId
        addressStreet
        addressText
        deliveryCost
        deliveryName
        email
        firstName
        orderItems {
          offer {
            id
            name
            article
            images
          }
        }
      }
    }
  }
`

export const GET_ORDER_HISTORY = gql`
  query historyStatusChangeOrder($id: Int!) {
    historyStatusChangeOrder(id: $id) {
      createdAt
      id
      statusGroup {
        active
        code
        name
      }
      status {
        active
        name
        code
      }
    }
  }
`

export const CHECK_USER_RECOVERY_TOKEN = gql`
  query checkedRecoveryToken($token: String!) {
    checkedRecoveryToken(token: $token) {
      success
      message
      code
    }
  }
`

export const GET_PUBLIC_DOCUMENT = gql`
  query document($key: String!, $siteCode: String!) {
    document(key: $key, siteCode: $siteCode) {
      name
      value
    }
  }
`

export const GET_PRODUCTS_SEARCH_HINT = gql`
  query productNamesForSearchHint($firstLetters: String!) {
    productNamesForSearchHint(firstLetters: $firstLetters) {
      name
      imageUrl
      slug
    }
  }
`
