<template>
    <SimpleModalWindow
        :class="$style['window']"
        :opened="$store.getters['modals/opened']('login')"
        @close="close('login')"
    >
        <template #title>
            {{ $t('auth_login') }}
        </template>

        <div
            v-if="$route.name === 'cart'"
            :class="[$style['window__description'], 'sw-text']"
        >
            {{ $t('auth_log_in_or_register') }}
        </div>

        <SwForm
            ref="form"
            :class="$style['window__form']"
            @submit.prevent="login"
        >
            <SwFormInput
                v-model="values.login"
                :label="$t('auth_email')"
                :errors="errors['login'] || []"
                :class="$style['window__row']"
                :readonly="isDemo"
                name="login"
                rules="required|email"
                clearable
                @input="$set(errors, 'login', [])"
            />

            <SwFormInput
                v-model="values.password"
                :label="$t('auth_password')"
                :errors="errors['password'] || []"
                :class="$style['window__row']"
                :readonly="isDemo"
                type="password"
                name="password"
                rules="required"
                clearable
                @input="$set(errors, 'password', [])"
            />

            <div :class="$style['window__row']">
                <SwLink @click="openRecoveryModal">
                    {{ $t('auth_forgot_password') }}
                </SwLink>
            </div>

            <div :class="$style['window__row']">
                <SwButton
                    :disabled="$store.state.isLoading"
                    type="submit"
                    wide
                >
                    {{ $t('auth_login') }}
                </SwButton>
            </div>
        </SwForm>

        <div :class="[$style['window__register'], 'sw-text']">
            {{ $t('auth_dont_have_account') }}
            <SwLink @click="openRegisterModal">
                {{ $t('auth_register_now') }}
            </SwLink>
        </div>

        <div :class="[$style['window__policy'], 'sw-text']">
            {{ $t('auth_agree_policy') }}
            <SwLink @click="openPolicyModal">
                {{ $t('auth_agree_policy_link') }}
            </SwLink>
        </div>

        <UiLoader
            v-show="$store.state.isLoading"
            fixed
            overlay
        />
    </SimpleModalWindow>
</template>

<script>
import SimpleModalWindow from '@@/website/components/common/SimpleModalWindow'

import { getValidationErrors } from '@@/website/utils/graphql'
import gql from 'graphql-tag'
import { isApolloError } from 'apollo-client'

const LOGIN_MUTATION = gql`
  mutation login($input: LoginInput!) {
    payload: login (input: $input) {
      token: accessToken
      user {
        firstName
        lastName
        gender
        birthday
        email
        phone
        address {
          id
          cityId
          city
          streetId
          street
          building
          flat
        }
        info {
          bonuses
        }
      }
    }
  }
`

export default {
  name: 'ModalLogin',

  components: {
    SimpleModalWindow
  },

  data: () => ({
    values: {
      login: '',
      password: ''
    },
    errors: {}
  }),

  computed: {
    isDemo () {
      return 'admin' in this.$store.state
    }
  },

  methods: {
    async login () {
      if (this.$refs.form && !(await this.$refs.form.validate())) {
        this.$notifications.error(this.$t('form_contains_errors'))
        return
      }

      try {
        this.$store.commit('lock')

        /** @type {{payload: { user: GqlUser, token:string }}} */
        const { data } = await this.$apollo.mutate({
          mutation: LOGIN_MUTATION,
          variables: {
            input: this.values
          }
        })

        await this.$apolloProvider.defaultClient.resetStore()
        await this.$store.dispatch('login', data.payload)

        this.close('login')
        if (this.$route.name === 'cart') {
          await this.$router.push({ name: 'checkout' })
        }
      } catch (e) {
        const errors = getValidationErrors(e)
        if (isApolloError(e) && errors !== undefined) {
          this.errors = errors
        } else {
          console.error(e)
        }
      } finally {
        this.$store.commit('unlock')
      }
    },

    openPolicyModal () {
      this.close('login')
      this.$store.dispatch('openPublicDocument', 'document_privacy_policy')
    },

    openRecoveryModal () {
      this.close('login')
      this.open('recoveryRequest')
    },

    openRegisterModal () {
      this.close('login')
      this.open('registration')
    },

    open (name) {
      this.$store.commit('modals/open', name)
    },

    close (name) {
      this.$store.commit('modals/close', name)
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.window {
  padding: 16px 24px;
  z-index: 100;
  @media screen and (max-width: @bp-mobile-small) {
    width: 100%;
    padding: 0;
  }

  :global(.sw-modal-window__container) {
    @media screen and (max-width: @bp-mobile-small) {
      max-width: 100% !important;
      max-height: 100% !important;
      height: 100%;
    }
  }

  :global(.sw-modal-window__extra) {
    position: relative;
    padding: 0;
    margin: 16px auto auto auto;
    box-shadow: 0 14px 24px rgba(31, 31, 46, 0.16);
    border-radius: 12px;
  }

  :global(.sw-panel) {
    padding: 15px 20px;
    @media screen and (max-width: @bp-mobile-small) {
      height: 100%;
      box-sizing: border-box;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;
  }

  &__row {
    margin-bottom: 16px;
  }

  &__restore {
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    color: @black-title;
    cursor: pointer;

    &:hover {
      color: @brand-200;
    }

    a {
      font-weight: normal;
      color: @grey-800 !important;
      &:hover {
        color: @brand-200 !important;
      }
    }
  }

  &__register {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
  }

  &__policy {
    padding: 0 15px;
    font-size: 12px;
    line-height: 16px;
    color: @grey-800;
    text-align: center;
    @media screen and (max-width: @bp-mobile-small) {
      margin-top: auto;
    }
  }
}
</style>
