import gql from 'graphql-tag'

export const SEND_RECOVERY_LINK = gql`
  mutation recoverySendLink($input: RecoveryInput!) {
    recoverySendLink(input: $input) {
      code
      message
      success
    }
  }
`

export const UPDATE_PROFILE_PASSWORD = gql`
  mutation changePassword($comparePassword: String!, $newPassword: String!, $password: String!) {
    changePassword(comparePassword: $comparePassword, newPassword: $newPassword, password: $password) {
      message
      success
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation updateProfile($input: ProfileInput) {
    updateProfile(input: $input) {
      success
      message
    }
  }
`

export const CART_PREPARING = gql`
  mutation cartPreparingForPayment {
    cartPreparingForPayment {
      success
    }
  }
`

export const CART_ADD = gql`
  mutation cartAdd($id: Int!, $quantity: Float) {
    cartAdd(id: $id, quantity: $quantity) {
      success
      cartItem {
        id
        quantity
      }
    }
  }
`

export const CART_ITEM_REMOVE = gql`
  mutation cartDelete($id: Int!) {
    cartDelete(id: $id) {
      success
      message
    }
  }
`
export const CART_ITEM_UNDO_REMOVE = gql`
  mutation cartCancelDeleted($id: Int!) {
    cartCancelDeleted(id: $id) {
      success
      message
    }
  }
`

export const CART_QUANTITY_CHANGE = gql`
  mutation cartAssign($id: Int!, $quantity: Float!) {
    cartAssign(id: $id, quantity: $quantity) {
      success
      message
    }
  }
`

export const SEND_ORDER = gql`
  mutation orderPayment(
    $address: OrderAddressInput = {}
    $bonuses: Int
    $comment: String
    $customer: OrderCustomerInput = {}
    $paymentCode: String
    $returnUrl: String
  ) {
    orderPayment(
      address: $address
      bonuses: $bonuses
      comment: $comment
      customer: $customer
      paymentCode: $paymentCode
      returnUrl: $returnUrl
    ) {
      success
      link
    }
  }
`

export const REGISTER_USER = gql`
  mutation registration($input: RegistrationInput!) {
    registration(input: $input) {
      accessToken
      expiresIn
      message
      refreshToken
      success
      tokenType
      info: user {
        birthday
        email
        firstName
        gender
        lastName
        phone
        address {
          building
          city
          flat
          cityId
          housing
          house
          id
          street
          streetId
        }
        info {
          bonuses
        }
      }
    }
  }
`

export const RECOVER_PASSWORD = gql`
  mutation recoverySetPassword($input: RecoveryPasswordInput!) {
    recoverySetPassword(input: $input) {
      success
      message
      code
    }
  }
`

export const REPEAT_CART = gql`
  mutation repeatCart($id: Int!) {
    repeatCart(id: $id) {
      success
      code
    }
  }
`

export const FEEDBACK = gql`
  mutation feedback($input: FeedbackInput!, $pivot_id: Int!) {
    feedback(input: $input, pivot_id: $pivot_id) {
      message
      success
    }
  }
`
