import {
  ApolloClient,
  ApolloError,
  isApolloError,
} from 'apollo-client'
import {
  ErrorMessageList,
  getValidationErrors
} from '@@/website/utils/graphql'
import {
  ActionTree,
  MutationTree,
  Store
} from 'vuex'
import { State as RootState } from './index'

import {
  GET_CART_ITEMS,
  GET_ORDER_TOTAL,
  SEND_ORDER
} from '@@/graphQL/web'

export type Customer = {
  firstName: string|null;
  lastName: string|null;
  birthday: string|null;
  email: string|null;
  phone: string|null;
}

export type Address = {
  city: {
    id: number;
    name: string;
  },
  street: {
    id: number;
    name: string;
  },
  building: string;
  flat: string;
  comment: string|null;
}

export type Delivery = {
  code: string|null;
  address: Address
}

export type Payment = {
  code: string|null;
}

export type State = {
  customer: Customer;
  delivery: Delivery;
  bonuses: number|null;
  payment: {
    code: string|null;
  };
  errors: ErrorMessageList;
}

export const state = (): State => ({
  customer: {
    firstName: null,
    lastName: null,
    birthday: null,
    email: null,
    phone: null,
  },
  delivery: {
    code: null,
    address: {
      city: { id: 0, name: '' },
      street: { id: 0, name: '' },
      building: '',
      flat: '',
      comment: null
    }
  },
  bonuses: null,
  payment: {
    code: null,
  },
  errors: {}
})

export const mutations: MutationTree<State> = {
  setCustomer (state: State, customer: Partial<Customer>) {
    state.customer = {
      ...state.customer,
      ...customer
    }
  },

  setDeliveryCode (state: State, code) {
    state.delivery.code = code
  },

  setDeliveryAddress (state: State, address: Partial<Address>) {
    state.delivery.address = {
      ...state.delivery.address,
      ...address
    }
  },

  setBonuses (state: State, bonuses: number | null) {
    state.bonuses = bonuses
  },

  setPayment (state: State, payment: Partial<Payment>) {
    state.payment = {
      ...state.payment,
      ...payment
    }
  },

  setErrors (state: State, errors: ErrorMessageList) {
    state.errors = {
      ...state.errors,
      ...errors
    }
  },

  reset (state: State) {
    state.customer.firstName = null
    state.customer.lastName = null
    state.customer.email = null
    state.customer.phone = null

    state.delivery.code = null
    state.delivery.address = {
      city: { id: 0, name: '' },
      street: { id: 0, name: '' },
      building: '',
      flat: '',
      comment: null
    }

    state.bonuses = null

    state.payment.code = null

    state.errors = {}
  }
}

export const actions: ActionTree<State, RootState> = {
  async send (this: Store<RootState>, { state, commit }, { siteCode, returnUrl }) {
    try {
      const apollo: ApolloClient<any> = this.app.apolloProvider?.defaultClient as ApolloClient<any>
      const { data } = await apollo.mutate({
        mutation: SEND_ORDER,
        variables: {
          returnUrl,
          paymentCode: state.payment.code,
          address: {
            deliveryCode: state.delivery.code,
            cityId: state.delivery.address.city.id,
            city: state.delivery.address.city.name,
            streetId: state.delivery.address.street.id,
            street: state.delivery.address.street.name,
            building: state.delivery.address.building,
            flat: state.delivery.address.flat
          },
          bonuses: state.bonuses,
          comment: state.delivery.address.comment,
          customer: {
            firstName: state.customer.firstName,
            lastName: state.customer.lastName,
            birthday: state.customer.birthday,
            email: state.customer.email,
            phone: state.customer.phone
          }
        },
        refetchQueries: [{
          query: GET_CART_ITEMS,
          variables: {
            siteCode
          }
        }, {
          query: GET_ORDER_TOTAL,
          variables: {
            code: state.delivery.code,
            bonuses: state.bonuses,
            siteCode
          }
        }]
      })

      return { ...data.orderPayment, done: true }
    } catch (e) {
      const errors = getValidationErrors(e as ApolloError)
      if (isApolloError(e as Error) && errors !== undefined) {
        commit('setErrors', errors)
      } else {
        console.error(e)
      }
      return { done: false }
    }
  }
}
