<template>
    <div
        v-if="pageCount > 1"
        :key="current"
        class="sw-pagination"
    >
        <SwButton
            v-if="current !== 1"
            class="sw-pagination__link"
            light
            small
            borderless
            @click="$emit('change', current - 1)"
        >
            <template #leading-icon>
                <SvgSpriteArrowLeft
                    width="16"
                    height="16"
                    style="color: #14142A"
                />
            </template>
        </SwButton>

        <template v-for="(item, index) in pages">
            <span
                v-if="index === 1 && item.page > 2"
                :key="item.page - 1"
                class="sw-pagination__dots"
            >
                ...
            </span>

            <SwButton
                :key="item.page"
                :light="!item.current"
                :class="{
                    'sw-pagination__link': true,
                    'sw-pagination__link_current': item.current,
                }"
                borderless
                small
                @click="$emit('change', item.page)"
            >
                {{ item.page }}
            </SwButton>
        </template>

        <SwButton
            v-if="current !== pageCount"
            class="sw-pagination__link"
            small
            light
            inverted
            borderless
            @click="$emit('change', current + 1)"
        >
            <template #leading-icon>
                <SvgSpriteArrowLeft
                    width="16"
                    height="16"
                    style="color: #14142A; transform: rotate(180deg)"
                />
            </template>
        </SwButton>
    </div>
</template>

<script>
import SvgSpriteArrowLeft from './sprite-arrow-left.svg'
import SwButton from '@@/framework/components/SwButton'

export default {
  name: 'SwPagination',

  components: {
    SvgSpriteArrowLeft,
    SwButton
  },

  props: {
    total: {
      type: Number,
      default: 1
    },

    current: {
      type: Number,
      default: 1
    },

    limit: {
      type: Number,
      default: 1
    },

    pageCount: {
      type: Number,
      default: 10
    }
  },

  data () {
    return {
      update: 0
    }
  },

  computed: {
    pages () {
      const pages = [{ page: 1, current: this.current === 1 }]
      const next = this.pageCount <= 5 || this.current <= 3
        ? 2
        : this.current <= this.pageCount - 3
          ? this.current - 1
          : this.pageCount - 3

      for (let i = next; i < next + 4; i++) {
        if (i <= this.pageCount) {
          pages.push({ page: i, current: this.current === i })
        }
      }

      return pages
    }
  }
}
</script>

<style lang="less">
@import (reference) "./assets/Web.less";

.sw-pagination {
  display: flex;
  justify-content: flex-start;

  &__dots {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 26px;
  }

  &__link {
    min-width: 40px;
    min-height: 40px;
    padding: 0;
    margin: 0 4px;

    .sw-button__text {
      color: #0d0f1f
    }

    &_current {
      .sw-button__text {
        color: white !important;
      }
    }
  }
}
</style>
