import {
  GetterTree,
  MutationTree
} from 'vuex'
import { State as RootState } from '@@/website/store'

export type State = {
  email: string|null
}

export const state = (): State => ({
  email: null,
})

export const getters: GetterTree<State, RootState> = {
  email: (state: State) => state.email
}

export const mutations: MutationTree<State> = {
  email: (state: State, email: string|null) => {
    state.email = email
  }
}
