<template>
    <div
        v-if="email"
        :class="{
            'sw-email': true,
            'sw-email_inverted': inverted
        }"
    >
        <a :href="'mailto:' + email">
            {{ email }}
        </a>
    </div>
</template>

<script>
export default {
  name: 'SwEmail',

  props: {
    email: {
      type: String,
      default: ''
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less">
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.sw-email {
  a {
    font-family: var(--sw-font-texts);
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    color: @grey-900;
  }

  &_inverted {
    a {
      color: rgba(255, 255, 255, 0.4)
    }
  }
}
</style>
