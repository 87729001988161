import { NuxtContext } from '@@/website/types/nuxt'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (isLoading: boolean, countModifier: number, { store }: NuxtContext) => {
  isLoading
    ? store.commit('lock')
    : store.commit('unlock')

  if ('admin' in store.state) {
    isLoading
      ? store.commit('admin/siteConstructor/lock')
      : store.commit('admin/siteConstructor/unlock')
  }
}
