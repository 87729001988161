<template>
    <SwContainer
        v-bind="{
            stretched,
            paddingY,
            bgColor,
            bgImage,
            bgOpacity,
        }"
    >
        <div
            :class="{
                [$style['block']]: true,
                [$style['block_inverted']]: inverted,
                [$style['block_reversed']]: reversed,
            }"
        >
            <div :class="$style['block__body']">
                <h1
                    :class="[$style['block__title'], 'sw-title']"
                    v-html="title"
                />

                <div
                    :class="[$style['block__text'], 'sw-text']"
                    v-html="text"
                />

                <template v-if="noIndex">
                    <client-only>
                        <nofollow>
                            <noindex>
                                <SwButton
                                    v-if="buttonUrl.includes('//')"
                                    :href="buttonUrl"
                                    target="_blank"
                                >
                                    {{ buttonLabel }}
                                </SwButton>

                                <SwButton v-else :to="buttonUrl">
                                    {{ buttonLabel }}
                                </SwButton>
                            </noindex>
                        </nofollow>
                    </client-only>
                </template>

                <template v-else>
                    <SwButton
                        v-if="buttonUrl.includes('//')"
                        :href="buttonUrl"
                        target="_blank"
                    >
                        {{ buttonLabel }}
                    </SwButton>

                    <SwButton v-else :to="buttonUrl">
                        {{ buttonLabel }}
                    </SwButton>
                </template>
            </div>

            <SwImage
                :src="image"
                :alt="title"
                :class="$style['block__image']"
                width="426"
                height="-"
            />
        </div>
    </SwContainer>
</template>

<script>
export default {
  name: 'TextAndImage',

  props: {
    title: {
      type: String,
      default: 'Заголовок'
    },

    text: {
      type: String,
      default: 'Используйте текст над изображением, чтобы дать вашим клиентам представление о вашем бренде. Выберите изображение и текст, который относится к вашему стилю и истории.'
    },

    image: {
      type: String,
      default: 'https://w-dog.ru/wallpapers/13/12/539639610160812/chajnik-chaj-listya-chaya.jpg'
    },

    buttonLabel: {
      type: String,
      default: 'Кнопка CTA'
    },

    buttonUrl: {
      type: String,
      default: '/'
    },

    noIndex: {
      type: Boolean,
      default: false
    },

    stretched: {
      type: Boolean,
      default: false
    },

    paddingY: {
      type: String,
      default: 's'
    },

    bgColor: {
      type: String,
      default: '#FFFFFF'
    },

    bgImage: {
      type: String,
      default: ''
    },

    bgOpacity: {
      type: String,
      default: '0%'
    },

    inverted: {
      type: Boolean,
      default: false
    },

    reversed: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "../../../../assets/Web.less";

.block {
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto 0;

  &_reversed {
    flex-direction: row-reverse;
  }

  @media screen and (max-width: @bp-mobile-small) {
    flex-direction: column;
  }

  &__body {
    flex-grow: 1;
    @media screen and (max-width: @bp-mobile-small) {
      margin-bottom: 64px;
    }
  }

  &__text {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &__title,
  &__text {
    margin-top: 0;
    margin-bottom: 24px;
    @media screen and (max-width: @bp-mobile-big) {
      margin-bottom: 32px;
    }
  }

  &_inverted &__title,
  &_inverted &__text {
    color: #FFFFFF;
  }

  &__image {
    width: 100%;
  }

  &__image:global(.sw-image) {
    flex-shrink: 0;
    max-width: 426px;
    height: 400px;
    object-fit: cover;
    margin-left: 114px;

    @media screen and (max-width: @bp-mobile-big) {
      max-width: 277px;
      height: auto;
      margin-left: 75px;
      aspect-ratio: 1;
    }
  }

  &_reversed &__image {
    margin-left: 0;
    margin-right: 114px;

    @media screen and (max-width: @bp-mobile-big) {
      margin-left: 0;
      margin-right: 75px;
    }
  }

  &__image:global(.sw-image) {
    @media screen and (max-width: @bp-mobile-small) {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}
</style>
