<template>
    <div
        class="sw-loader"
        :style="{
            '--sw-loader-size': `${size}px`,
            '--sw-loader-stroke': `${stroke}px`,
        }"
    >
        <div class="sw-loader__container">
            <div class="sw-loader__spinner" />
        </div>
    </div>
</template>

<script>
export default {
  name: 'SwLoader',

  props: {
    size: {
      type: Number,
      default: 32
    },

    stroke: {
      type: Number,
      default: 4
    }
  }
}
</script>

<style scoped lang="less">
@import (reference) "./assets/Web.less";

@keyframes sw-loader-spin {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}

.sw-loader {
  display: inline-block;
  overflow: hidden;
  width: var(--sw-loader-size);
  height: var(--sw-loader-size);
  margin: auto;

  &__container {
    width: var(--sw-loader-size);
    height: var(--sw-loader-size);
    backface-visibility: hidden;
    border: var(--sw-loader-stroke) solid var(--sw-color-main-100);
    border-radius: 50%;
    box-sizing: border-box;
    transform: translateZ(0) scale(1);
    transform-origin: 0 0;
    position: relative;
  }

  &__spinner {
    width: var(--sw-loader-size);
    height: var(--sw-loader-size);
    border-color: var(--sw-color-main-500) var(--sw-color-main-500) transparent transparent;
    border-radius: 50%;
    border-style: solid;
    border-width: var(--sw-loader-stroke);
    animation: sw-loader-spin 1s linear infinite;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

</style>
