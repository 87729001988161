<template>
    <div class="sw-calendar-navigation">
        <SvgIconPrev
            class="sw-calendar-navigation__button"
            @click="$emit('prev')"
        />

        <div
            :class="{
                'sw-calendar-navigation__clickable': $listeners.main
            }"
            @click="$listeners.main ? $emit('main') : null"
        >
            <slot />
        </div>

        <SvgIconNext
            class="sw-calendar-navigation__button"
            @click="$emit('next')"
        />
    </div>
</template>

<script>
import SvgIconNext from './sprite-next.svg'
import SvgIconPrev from './sprite-prev.svg'

export default {
  name: 'SwCalendarNavigation',

  components: {
    SvgIconNext,
    SvgIconPrev
  }
}
</script>

<style lang="less">
.sw-calendar-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  font-weight: 600;

  &__clickable {
    cursor: pointer;
  }

  &__button {
    color: #14142A;
    cursor: pointer;
  }
}
</style>
