import Vue from 'vue'
import Framework, { setup } from '@@/framework'

Vue.use(Framework, true)

export default (context, inject) => {
  if (process.server) {
    context.$simlaweb = setup()
    context.ssrContext.nuxt.simlawebFramework = {
      settings: context.$simlaweb.settings,
      modals: context.$simlaweb.modals.data.registry
    }
  } else if (window?.__NUXT__?.simlawebFramework) {
    context.$simlaweb = setup(window.__NUXT__.simlawebFramework)
  } else {
    context.$simlaweb = setup()
  }

  inject('simlaweb', context.$simlaweb)
}
