<template>
    <div :style="styles">
        <slot />
    </div>
</template>

<script>
import palette from '@@/framework/palette'

export default {
  name: 'SwRoot',

  data: () => ({
    mounted: true
  }),

  computed: {
    hasOpenedModals () {
      return this.$simlaweb.modals.data.registry.length > 0
    },

    styles () {
      const design = this.$simlaweb.settings

      return {
        '--sw-color-main-800': palette.get(design.colors.main, 800),
        '--sw-color-main-700': palette.get(design.colors.main, 700),
        '--sw-color-main-600': palette.get(design.colors.main, 600),
        '--sw-color-main-500': palette.get(design.colors.main, 500),
        '--sw-color-main-400': palette.get(design.colors.main, 400),
        '--sw-color-main-300': palette.get(design.colors.main, 300),
        '--sw-color-main-200': palette.get(design.colors.main, 200),
        '--sw-color-main-100': palette.get(design.colors.main, 100),
        '--sw-color-main-000': palette.get(design.colors.main, 0),
        '--sw-font-headings': `'${design.fonts.headings}', -apple-system, BlinkMacSystemFont, sans-serif`,
        '--sw-font-texts': `'${design.fonts.texts}', -apple-system, BlinkMacSystemFont, sans-serif`,
        fontFamily: 'var(--sw-font-texts)'
      }
    }
  },

  watch: {
    hasOpenedModals () {
      if (this.mounted) {
        document.body.classList.toggle('open-modal', this.hasOpenedModals)
      }
    }
  },

  mounted () {
    this.mounted = true
    document.body.classList.toggle('open-modal', this.hasOpenedModals)
  }
}
</script>
