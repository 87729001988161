import Vue from 'vue'

import UiInputNumber from '@omnica/input-number-vue2'
import UiScrollbar from '@omnica/scrollbar-vue2'

import OmnicaLoaderPlugin from '@omnica/loader-vue2'
import OmnicaPopperPlugin from '@omnica/popper-vue2'

Vue.component('UiInputNumber', UiInputNumber)
Vue.component('UiScrollbar', UiScrollbar)

Vue.use(OmnicaLoaderPlugin)
Vue.use(OmnicaPopperPlugin)
