<template>
    <SwTransition name="fade-2">
        <div v-if="opened" class="sw-modal-window">
            <div :style="{ maxWidth }" class="sw-modal-window__container">
                <SwPanel
                    :class="{
                        'sw-modal-window__body': true,
                        'sw-modal-window__body_outside': closeOutside,
                    }"
                    :elevation="elevation"
                    :transparent="transparent"
                >
                    <div
                        :class="{
                            'sw-modal-window__close': true,
                            'sw-modal-window__close_outside': closeOutside,
                        }"
                        @click="close"
                    >
                        <SwIconButton>
                            <SvgIconCross />
                        </SwIconButton>
                    </div>

                    <slot />
                </SwPanel>

                <div
                    v-if="$slots.extra"
                    class="sw-modal-window__extra"
                >
                    <slot name="extra" />
                </div>
            </div>

            <div class="sw-modal-window__overlay" />
        </div>
    </SwTransition>
</template>

<script>
import SvgIconCross from '@@/framework/icons/cross.svg'
import SwIconButton from '@@/framework/components/SwIconButton'
import SwTransition from '@@/framework/components/SwTransition'

import uuid4 from '@@/framework/utils/uuid4'

export default {
  name: 'SwModalWindow',

  components: {
    SvgIconCross,
    SwIconButton,
    SwTransition
  },

  props: {
    opened: {
      type: Boolean,
      default: false
    },

    elevation: {
      type: [Number, String],
      default: 1
    },

    width: {
      type: [Number, String],
      default: 352
    },

    transparent: {
      type: Boolean,
      default: false
    },

    closeOutside: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    uuid: uuid4()
  }),

  computed: {
    maxWidth () {
      return this.width
        ? !isNaN(Number(this.width))
            ? this.width + 'px'
            : this.width
        : 'auto'
    }
  },

  watch: {
    opened () {
      this.$simlaweb.modals.toggle(this.uuid, this.opened)
    }
  },

  mounted () {
    /** @param {KeyboardEvent} event */
    const onEsc = (event) => {
      if (event.key === 'Escape') {
        this.close()
      }
    }

    document.addEventListener('keydown', onEsc)
    this.$simlaweb.modals.toggle(this.uuid, this.opened)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEsc)
      this.$simlaweb.modals.toggle(this.uuid, false)
    })
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less">
@import (reference) "./assets/Web.less";

.sw-modal-window {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  touch-action: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;

  &__container {
    width: 100%;
    margin: auto;
    touch-action: auto;
  }

  &__body {
    padding: 16px 24px;
    position: relative;
    z-index: 13;
    @media screen and (max-width: @bp-mobile-small) {
      border-radius: 0 !important;
    }

    &_outside {
      padding: 0
    }
  }

  &__extra {
    .padding-container;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: white;
    margin: 100px auto auto auto;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 14;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 1;

    &_outside {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 8px;
      background: white;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      transform: translate(calc(100% + 8px), 0%);
      top: 0;
      right: 0;
    }
  }

  &__overlay {
    content: "";
    background: @black-title;
    opacity: 0.5;
    z-index: 12;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
