var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showPlaceholder)?_c('SwImagePlaceholder',{style:({
        maxWidth: (_vm.width + "px"),
        maxHeight: (_vm.height + "px"),
    }),attrs:{"title":_vm.alt}}):(_vm.image.src)?_c('img',_vm._g({directives:[{name:"image-fallback",rawName:"v-image-fallback"}],class:{
        'sw-image': true,
        'sw-image_roundings_none': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.NONE,
        'sw-image_roundings_small': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.SMALL,
        'sw-image_roundings_large': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.LARGE
    },attrs:{"src":_vm.image.src,"srcSet":_vm.image.srcSet,"alt":_vm.alt,"width":_vm.width,"height":_vm.height,"title":_vm.alt}},_vm.$listeners)):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }