<template>
    <div class="sw-badge">
        <slot />

        <div
            v-if="$slots.count"
            :style="{ color: inverted ? '#1f1f2e' : '#ffffff' }"
            class="sw-badge__count sw-text"
        >
            <slot name="count" />
        </div>
    </div>
</template>

<script>
export default {
  name: 'SwBadge',

  props: {
    count: {
      type: Number,
      default: 0
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>
