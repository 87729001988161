<template>
    <component
        :is="tag"
        :type="type"
        :to="to"
        :href="href"
        :class="classes"
        :style="styles"
        v-on="$listeners"
    >
        <span
            v-if="$slots['leading-icon']"
            class="sw-button__icon"
        >
            <slot name="leading-icon" />
        </span>

        <div
            v-if="$slots['default']"
            class="sw-button__text sw-text"
        >
            <slot />
        </div>

        <span
            v-if="$slots['trailing-icon']"
            class="sw-button__icon"
        >
            <slot name="trailing-icon" />
        </span>
    </component>
</template>

<script>
import Vue from 'vue'

import {
  CASING,
  ROUNDINGS
} from '@@/framework/types'

const INVERT_COLORS = {
  '--sw-color-main-500': 'rgba(255, 255, 255, 1)',
  '--sw-color-main-600': 'rgba(255, 255, 255, 0.8)',
  '--sw-color-main-700': 'rgba(255, 255, 255, 0.6)',
  '--sw-color-main-300': 'rgba(255, 255, 255, 0.4)',
  '--sw-color-main-200': 'rgba(255, 255, 255, 0.2)',
  '--sw-color-main-000': 'rgba(255, 255, 255, 0)'
}

const xor = (a, b) => (a && !b) || (!a && b)

export default {
  name: 'SwButton',

  props: {
    type: {
      type: String,
      default: 'button'
    },

    to: {
      type: [Object, String],
      default: null
    },

    href: {
      type: String,
      default: null
    },

    link: {
      type: Boolean,
      default: false
    },

    transparent: {
      type: Boolean,
      default: false
    },

    light: {
      type: Boolean,
      default: false
    },

    borderless: {
      type: Boolean,
      default: false
    },

    wide: {
      type: Boolean,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    },

    inverted: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      hasLeadingIcon: false,
      hasTrailingIcon: false,
      hasText: false
    }
  },

  computed: {
    tag () {
      return this.to && 'NuxtLink' in Vue.options.components
        ? 'NuxtLink'
        : this.href
          ? 'a'
          : 'button'
    },

    classes () {
      return {
        'sw-button': true,
        'sw-button_outlined': this.light,
        'sw-button_transparent': this.transparent,
        'sw-button_borderless': this.borderless,
        'sw-button_uppercase': this.$simlaweb.settings.buttonsTextCasing === CASING.UPPERCASE,
        'sw-button_link': this.link,
        'sw-button_has-only-icon': xor(this.hasLeadingIcon, this.hasTrailingIcon) && !this.hasText,
        'sw-button_has-text-with-leading-icon': this.hasText && this.hasLeadingIcon && !this.hasTrailingIcon,
        'sw-button_has-text-with-trailing-icon': this.hasText && !this.hasLeadingIcon && this.hasTrailingIcon,
        'sw-button_wide': this.wide,
        'sw-button_size_sm': this.small,
        'sw-button_roundings_none': this.$simlaweb.settings.roundings === ROUNDINGS.NONE,
        'sw-button_roundings_sm': this.$simlaweb.settings.roundings === ROUNDINGS.SMALL,
        'sw-button_roundings_lg': this.$simlaweb.settings.roundings === ROUNDINGS.LARGE,
        'sw-button_inverted': this.inverted,
        'sw-button_disabled': this.disabled
      }
    },

    styles () {
      return this.inverted ? { ...INVERT_COLORS } : {}
    }
  },

  mounted () {
    this._observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (['childList', 'subtree'].includes(mutation.type)) {
          this.updateFlags()
        }
      }
    })
    this._observer.observe(this.$el, { childList: true, subtree: true })
    this.updateFlags()
  },

  beforeDestroy () {
    if (this._observer) {
      this._observer.disconnect()
      this._observer = null
    }
  },

  methods: {
    updateFlags () {
      this.hasLeadingIcon = !!this.$slots['leading-icon']
      this.hasTrailingIcon = !!this.$slots['trailing-icon']
      this.hasText = !!this.$slots['default']
    }
  }
}
</script>
