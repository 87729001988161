<template>
    <SwRoot class="app">
        <template v-if="$store.state.settings.isPublished">
            <ModalLogin />
            <ModalPasswordRecoveryRequest />
            <ModalPasswordRecoveryConfirm />
            <ModalPersonal />
            <ModalCatalogFilter />
            <ModalCatalogTree />
            <nuxt />
        </template>

        <LayoutError
            v-else
            :code="0"
        />
    </SwRoot>
</template>

<script>
import CustomScriptMixin from '../components/common/CustomScriptMixin'

import LayoutError from './error.vue'
import ModalCatalogFilter from '@@/website/components/modals/ModalCatalogFilter'
import ModalCatalogTree from '@@/website/components/modals/ModalCatalogTree'
import ModalLogin from '@@/website/components/modals/ModalLogin'
import ModalPasswordRecoveryConfirm from '@@/website/components/modals/ModalPasswordRecoveryConfirm'
import ModalPasswordRecoveryRequest from '@@/website/components/modals/ModalPasswordRecoveryRequest'
import ModalPersonal from '@@/website/components/modals/ModalPersonal'

export default {
  components: {
    LayoutError,
    ModalCatalogFilter,
    ModalCatalogTree,
    ModalLogin,
    ModalPasswordRecoveryConfirm,
    ModalPasswordRecoveryRequest,
    ModalPersonal
  },

  mixins: [
    CustomScriptMixin
  ],

  head () {
    const isSitePublished = this.$store.state.settings.isPublished

    const link = []

    if (this.$store.getters.faviconHref) {
      link.push({
        hid: 'favicon',
        rel: 'icon',
        type: 'image/x-icon',
        href: this.$store.getters.faviconHref
      })
    }

    return {
      i18Head: this.$nuxtI18nSeo(),
      link,
      ...(isSitePublished && this.buildMetaForCustomScripts())
    }
  },

  watchQuery: true,

  async beforeMount () {
    if (this.$store.state.admin) {
      await this.$store.dispatch('setup', {
        fetchPolicy: 'no-cache'
      })
    } else {
      const lang = this.$store.state.settings.system_language
      await this.$i18n.setLocale(lang.toLowerCase())
    }
  },

  mounted () {
    const link = document.createElement('link')

    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = '/fonts.css'

    if (this.$store.state.admin) {
      link.href = '_nuxt/fonts.css'
    }

    document.head.appendChild(link)
  }
}
</script>

<style>
html,
body {
  margin: 0;
}

body.open-modal {
  overflow: hidden;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fff;
}

[class*="extra__wrap_"] {
  z-index: 11 !important;
}

a:link {
  text-decoration: none;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
</style>
