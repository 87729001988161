<template>
    <div
        :class="{
            [$style['content']]: true,
            [$style['content_center']]: position === 'center',
            [$style['content_right']]: position === 'right',
            [$style['content_inverted']]: inverted
        }"
    >
        <img
            v-if="image"
            :src="image"
            :alt="title"
            :class="$style['content__image']"
        >

        <div :class="$style['content__box']">
            <h3
                :class="[$style['content__title'], 'sw-title']"
                v-html="title"
            />

            <div
                :class="[$style['content__text'], 'sw-text']"
                v-html="text"
            />
        </div>
    </div>
</template>

<script>
export default {
  name: 'TextAndImage',

  props: {
    title: {
      type: String,
      default: 'Текст над изображением'
    },

    text: {
      type: String,
      default: 'Используйте текст над изображением, чтобы дать вашим клиентам представление о вашем бренде. Выберите изображение и текст, который относится к вашему стилю и истории.'
    },

    image: {
      type: String,
      default: 'https://w-dog.ru/wallpapers/13/12/539639610160812/chajnik-chaj-listya-chaya.jpg'
    },

    position: {
      type: String,
      default: 'left'
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.content {
  display: flex;
  align-items: center;
  height: 500px;
  padding: 48px;
  box-sizing: border-box;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  @media screen and (max-width: @bp-mobile-small) {
    padding: 24px;
  }

  &_center { justify-content: center; text-align: center; }
  &_right { justify-content: flex-end; }

  &_inverted {
    color: #FFFFFF;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__box {
    max-width: 606px;
    z-index: 10;
  }

  &_right &__box {
    max-width: 492px;
  }

  &__title {
    @media screen and (max-width: @bp-mobile-small) {
      font-size: 24px;
    }
  }

  &__text {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &_center &__text {
    text-align: center;
  }

  &_inverted &__title,
  &_inverted &__text {
    color: #FFFFFF;
  }
}
</style>
