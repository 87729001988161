<template>
    <label
        :class="{
            'sw-checkbox': true,
            'sw-checkbox_roundings_none': $simlaweb.settings.roundings === ROUNDINGS.NONE,
            'sw-checkbox_roundings_small': $simlaweb.settings.roundings === ROUNDINGS.SMALL,
            'sw-checkbox_roundings_large': $simlaweb.settings.roundings === ROUNDINGS.LARGE,
            'sw-checkbox_disabled': disabled
        }"
        :style="styles"
    >
        <span>{{ label }}</span>
        <input
            type="checkbox"
            :name="name"
            :checked="value"
            @change="$emit('change', !value)"
        >
        <span class="sw-checkbox__checkmark" />
    </label>
</template>

<script>
import { ROUNDINGS } from '@@/framework/types'

export default {
  name: 'SwCheckbox',

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    name: {
      type: String,
      required: true
    },

    value: {
      type: [String, Number, Boolean],
      default: false
    },

    label: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ROUNDINGS: () => ROUNDINGS,

    styles () {
      return this.invertFontColor
        ? {
            '--sw-color-main-500': 'rgba(255, 255, 255, 1)',
            '--sw-color-main-600': 'rgba(255, 255, 255, 0.8)',
            '--sw-color-main-700': 'rgba(255, 255, 255, 0.6)',
            '--sw-color-main-300': 'rgba(255, 255, 255, 0.4)',
            '--sw-color-main-200': 'rgba(255, 255, 255, 0.2)',
            '--sw-color-main-000': 'rgba(255, 255, 255, 0)'
          }
        : {}
    }
  }
}
</script>

<style lang="less">
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.sw-checkbox {
  display: block;
  min-width: fit-content;
  padding: 8px 8px 8px 40px;
  font-family: var(--sw-font-texts);
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    width: 16px;
    height: 16px;
    background-color: #FFFFFF;
    border: 2px solid @grey-600;
    position: absolute;
    top: 8px;
    left: 8px;
  }

  &:hover input ~ &__checkmark {
    border: 2px solid var(--sw-color-main-500);
  }
  &:hover input:checked ~ &__checkmark {
    background-color: var(--sw-color-main-600);
    border: 2px solid var(--sw-color-main-600);
  }
  input:checked ~ &__checkmark {
    background-color: var(--sw-color-main-500);
    border: 2px solid var(--sw-color-main-500);
  }
  &_disabled {
    pointer-events: none;
    color: @grey-800;
  }
  &_disabled input ~ &__checkmark {
    background-color: @grey-200;
  }
  &_disabled input:checked ~ &__checkmark {
    background-color: @grey-600;
    border: 2px solid @grey-600;
  }
  &__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ &__checkmark:after {
    display: block;
  }

  &__checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 2px 0 0 2px;
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
  }

  &_roundings_none &__checkmark { border-radius: 0; }
  &_roundings_small &__checkmark { border-radius: 6px; }
  &_roundings_large &__checkmark { border-radius: 8px; }
}
</style>
