import type { Dictionary } from 'vue-router/types/router'

export type FilterDefinition = {
  identity: string,
  key: string,
  type: string,
  name: string,
  max?: number,
  min?: number,
  values?: string[],
}

export type Filter = {
  identity: string,
  key: string;
  name: string;
  type: string,
  min?: number,
  max?: number,
  values?: string[],
}

export type FilterModel = {
  key: string;
  name: string;
  type: string,
  min?: number,
  max?: number,
  values?: Record<string, boolean>;
  isActive?: boolean;
  expanded: boolean;
}

export type Query = Dictionary<string | (string | null)[] | null | undefined>

export function createFilterModel (
  definitions: FilterDefinition[],
  filters: Map<string, Filter>
): FilterModel[] {
  return definitions.map((definition) => {
    const filter = filters.get(definition.key)

    const model: FilterModel = {
      key: definition.key,
      name: definition.name,
      type: definition.type,
      isActive: !!filter,
      expanded: !!filter,
    }

    if (definition.type === 'list') {
      const set = new Set(filter?.values ?? [])
      const values = {} as Record<string, boolean>

      definition.values?.forEach((value) => {
        values[value] = set.has(value)
      })

      model.values = values
    }

    if (definition.type === 'range') {
      const min = filter?.min || -Infinity
      const max = filter?.max || Infinity
      const definitionMin = definition.min ?? 0
      const definitionMax = definition.max ?? 0

      model.min = Math.max(min, definitionMin)
      model.max = Math.min(max, definitionMax)
    }

    return model
  })
}

export function createFilters (
  query: Query,
  definitions: Map<string, FilterDefinition>
): Filter[] {
  const filters: Filter[] = []

  Object.entries(query).forEach(([key, value]) => {
    const definition = definitions.get(key)

    if (!definition) {
      return
    }

    const filter: Filter = {
      key,
      type: definition.type,
      name: definition.name,
      identity: definition.identity,
    }

    if (definition.type === 'toggle' && typeof value === 'string' && value === 'y') {
      filters.push(filter)
    } else if (definition.type === 'range' && typeof value === 'string' && value.includes('~')) {
      const [minVal, maxVal] = value.split('~')

      const min = parseFloat(minVal)
      const max = parseFloat(maxVal)

      if (!isNaN(min) && !isNaN(max)) {
        filter.min = min
        filter.max = max
        filters.push(filter)
      }
    } else if (definition.type === 'list' && Array.isArray(value)) {
      filter.values = [...value.filter(v => !!v) as string[]]

      if (filter.values.length) {
        filters.push(filter)
      }
    }
  })

  return filters
}

function isRangeNotDefault (filter?: FilterModel, defaultFilter?: Filter) {
  if (!filter || !defaultFilter) {
    return false
  }

  const min = filter.min ?? 0
  const max = filter.max ?? 0

  const defaultMin = defaultFilter.min ?? 0
  const defaultMax = defaultFilter.max ?? 0

  return min !== defaultMin || max !== defaultMax
}

function normalize (record: Record<string, boolean>) {
  return Object.keys(record).filter(key => record[key])
}

export function createQuery (filters: FilterModel[], definitions: Map<string, FilterDefinition>) {
  return filters.reduce((query: Query, filter: FilterModel) => {
    if (!filter.key) {
      return query
    }

    if (filter.type === 'range' && filter.min && filter.max && isRangeNotDefault(filter, definitions.get(filter.key))) {
      query[filter.key] = `${filter.min}~${filter.max}`
    } else if (filter.type === 'toggle' && filter.isActive) {
      query[filter.key] = 'y'
    } else if (filter.type === 'list' && typeof filter.values === 'object' && filter.values !== null) {
      const list = normalize(filter.values)

      if (list.length) {
        query[filter.key] = list
      }
    }

    return query
  }, {})
}
