import Vue from 'vue'

import SwBadge from './components/SwBadge'
import SwButton from './components/SwButton'
import SwCalendar from './components/SwCalendar'
import SwCheckbox from './components/SwCheckbox'
import SwContainer from './components/SwContainer'
import SwDatepicker from './components/SwDatepicker'
import SwEmail from './components/SwEmail'
import SwForm from './components/SwForm'
import SwFormInput from './components/SwFormInput'
import SwIconButton from './components/SwIconButton'
import SwImage from './components/SwImage'
import SwImagePlaceholder from './components/SwImagePlaceholder'
import SwInput from './components/SwInput'
import SwInputDropdown from './components/SwInputDropdown'
import SwInputNumber from './components/SwInputNumber'
import SwLink from './components/SwLink'
import SwLoader from './components/SwLoader'
import SwModalSidebar from './components/SwModalSidebar'
import SwModalWindow from './components/SwModalWindow'
import SwPagination from './components/SwPagination'
import SwPanel from './components/SwPanel'
import SwRoot from './components/SwRoot'
import SwSwitch from './components/SwSwitch'
import SwTransition from './components/SwTransition'

/**
 * backgroundColor: string,
 */

import { CASING, ROUNDINGS } from '@@/framework/types'

/**
 * @param {{
 *   settings: Partial<Settings>,
 *   modals: string[],
 * }} [options]
 * @returns {{
 *   settings: Settings,
 *   modals: {
 *     data: { registry: string[] },
 *     add(uuid: string): void,
 *     remove(uuid: string): void
 *   }
 * }}
 */
export const setup = (options) => {
  const { settings, modals } = options || {}

  return {
    settings: Vue.observable({
      buttonsTextCasing: CASING.INITIAL,
      colors: {
        main: 'blue',
        background: '#ffffff'
      },
      favicon: '',
      fonts: {
        headings: '',
        texts: ''
      },
      roundings: ROUNDINGS.SMALL,
      ...(settings || {})
    }),

    modals: {
      data: Vue.observable({
        registry: [...(modals || [])]
      }),

      add (uuid) {
        if (!this.data.registry.includes(uuid)) {
          this.data.registry.push(uuid)
        }
      },

      remove (uuid) {
        const index = this.data.registry.indexOf(uuid)
        if (index !== -1) {
          this.data.registry.splice(index, 1)
        }
      },

      toggle (uuid, force = undefined) {
        const index = this.data.registry.indexOf(uuid)

        if (index === -1) {
          if (force) {
            this.data.registry.push(uuid)
          }
        } else if (!force) {
          this.data.registry.splice(index, 1)
        }
      }
    }
  }
}

// noinspection JSUnusedGlobalSymbols
export default {
  install (Vue, nuxt = false) {
    if (!nuxt) {
      const simlaweb = setup()

      Vue.mixin({
        beforeCreate () {
          this.$simlaweb = simlaweb
        }
      })
    }

    Vue.component('SwBadge', SwBadge)
    Vue.component('SwButton', SwButton)
    Vue.component('SwCalendar', SwCalendar)
    Vue.component('SwCheckbox', SwCheckbox)
    Vue.component('SwContainer', SwContainer)
    Vue.component('SwDatepicker', SwDatepicker)
    Vue.component('SwEmail', SwEmail)
    Vue.component('SwForm', SwForm)
    Vue.component('SwFormInput', SwFormInput)
    Vue.component('SwIconButton', SwIconButton)
    Vue.component('SwImage', SwImage)
    Vue.component('SwImagePlaceholder', SwImagePlaceholder)
    Vue.component('SwInput', SwInput)
    Vue.component('SwInputDropdown', SwInputDropdown)
    Vue.component('SwInputNumber', SwInputNumber)
    Vue.component('SwLink', SwLink)
    Vue.component('SwLoader', SwLoader)
    Vue.component('SwModalSidebar', SwModalSidebar)
    Vue.component('SwModalWindow', SwModalWindow)
    Vue.component('SwPagination', SwPagination)
    Vue.component('SwPanel', SwPanel)
    Vue.component('SwRoot', SwRoot)
    Vue.component('SwSwitch', SwSwitch)
    Vue.component('SwTransition', SwTransition)
  }
}
