import { MutationTree } from 'vuex'

export enum SortType {
  Max = 'max',
  Min = 'min',
  New = 'new',
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortMethod = {
  id: SortType;
  sort: string;
  order: SortOrder;
}

export type Filter = {
  key: string|undefined;
  name: string;
  min?: number,
  max?: number,
  values?: string[],
  identity?: string,
}

export type State = {
  term: string;
  sort: SortMethod|undefined;
  filters: Filter[];
  page: number;
  defaultSort: string,
  totalProductsCount: number,
  currentCategoryId: number,
}

export const SortMethodsAvailable: Record<SortType, SortMethod> = {
  [SortType.New]: { id: SortType.New, sort: 'novelty', order: SortOrder.Desc },
  [SortType.Max]: { id: SortType.Max, sort: 'max_price', order: SortOrder.Desc },
  [SortType.Min]: { id: SortType.Min, sort: 'min_price', order: SortOrder.Asc }
}

export const state = (): State => ({
  term: '',
  sort: undefined,
  filters: [],
  page: 1,
  defaultSort: SortMethodsAvailable[SortType.New].id,
  totalProductsCount: 0,
  currentCategoryId: 0,
})

export const mutations: MutationTree<State> = {
  term: (state: State, term: string) => {
    state.term = term
  },

  sort: (state: State, sort?: SortMethod) => {
    state.sort = sort ? { ...sort } : undefined
  },

  filters: (state: State, filters: Filter[]) => {
    state.filters = filters
  },

  page: (state: State, page: number) => {
    state.page = isNaN(page) ? 1 : Math.max(page, 1)
  },

  setTotalProductsCount: (state: State, count: number) => {
    state.totalProductsCount = count
  },

  setCurrentCategoryId: (state: State, id: number) => {
    state.currentCategoryId = id
  },

  setDefaultSort: (state: State, sort: string) => {
    state.defaultSort = sort
  },
}
