<template>
    <SwTransition name="fade-2">
        <div
            v-show="opened"
            class="sw-modal-sidebar-overlay"
            v-on="$listeners"
        >
            <SwTransition :name="`slide-${direction}`">
                <div
                    v-show="opened"
                    class="sw-modal-sidebar-body"
                >
                    <slot />
                </div>
            </SwTransition>
        </div>
    </SwTransition>
</template>

<script>
import SwTransition from '@@/framework/components/SwTransition'

import uuid4 from '@@/framework/utils/uuid4'

import DIRECTIONS from './enum/directions'
import SIZES from './enum/sizes'
import TYPES from './enum/types'

export default {
  name: 'SwModalSidebar',

  components: {
    SwTransition
  },

  props: {
    opened: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: TYPES.DEFAULT
    },

    direction: {
      type: String,
      default: DIRECTIONS.RIGHT
    },

    size: {
      type: String,
      default: SIZES.SM
    }
  },

  data: () => ({
    uuid: uuid4()
  }),

  watch: {
    opened () {
      this.$simlaweb.modals.toggle(this.uuid, this.opened)
    }
  },

  mounted () {
    this.$simlaweb.modals.toggle(this.uuid, this.opened)
    this.$once('hook:destroyed', () => {
      this.$simlaweb.modals.toggle(this.uuid, false)
    })
  }
}
</script>

<style lang="less">
@import (reference) "./assets/Web.less";

.sw-modal-sidebar-overlay {
  background: fade(@black-title, 50%);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.sw-modal-sidebar-body {
  display: flex;
  width: 344px;
  height: 100%;
  background: white;
  flex-direction: column;
  overflow-y: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  @media screen and (max-width: @bp-mobile-small) {
    width: 100%;
  }
}
</style>
