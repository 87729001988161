export const vueI18n = require('@@/i18n/config.js').default
export const vueI18nLoader = false
export const locales = [{"code":"ru","file":"ru-RU.js"},{"code":"en","file":"en-US.js"},{"code":"es","file":"es-ES.js"}]
export const defaultLocale = 'en'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'no_prefix'
export const lazy = true
export const langDir = '../lang/'
export const rootRedirect = null
export const detectBrowserLanguage = false
export const differentDomains = false
export const seo = false
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const skipSettingLocaleOnNavigate = true
export const skipNuxtState = false
export const IS_UNIVERSAL_MODE = true
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["ru","en","es"]
export const trailingSlash = undefined
