<template>
    <div
        :class="{
            'sw-input-number': true,
            'sw-input-number_inverted': inverted,
            'sw-input-number_large': size === SIZES.LARGE,
            'sw-input-number_small': size === SIZES.SMALL,
            'sw-input-number_roundings_none': $simlaweb.settings.roundings === ROUNDINGS.NONE,
            'sw-input-number_roundings_small': $simlaweb.settings.roundings === ROUNDINGS.SMALL,
            'sw-input-number_roundings_large': $simlaweb.settings.roundings === ROUNDINGS.LARGE,
            'sw-input-number_disabled': disabled,
        }"
        :style="inverted
            ? {
                '--sw-color-main-500': 'rgba(255, 255, 255, 1)',
                '--sw-color-main-600': 'rgba(255, 255, 255, 0.8)',
                '--sw-color-main-700': 'rgba(255, 255, 255, 0.6)',
                '--sw-color-main-300': 'rgba(255, 255, 255, 0.4)',
                '--sw-color-main-200': 'rgba(255, 255, 255, 0.2)',
                '--sw-color-main-000': 'rgba(255, 255, 255, 0)'
            }
            : {}"
        v-on="$listeners"
    >
        <ClientOnly>
            <UiInputNumber
                :value="value"
                :max="max"
                :min="min"
                :step="step"
                :disabled="disabled"
                @update:value="v => $emit('changeInputNumber', Math.floor(v))"
            />
        </ClientOnly>
    </div>
</template>

<script>
import { ROUNDINGS } from '@@/framework/types'

const SIZES = {
  LARGE: 'large',
  SMALL: 'small'
}

export default {
  name: 'SwInputNumber',

  props: {
    value: {
      type: Number,
      default: 1
    },

    max: {
      type: Number,
      default: Infinity
    },

    min: {
      type: Number,
      default: 0
    },

    step: {
      type: Number,
      default: 1
    },

    disabled: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: SIZES.SMALL
    },

    inverted: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ROUNDINGS: () => ROUNDINGS,
    SIZES: () => SIZES
  }
}
</script>

<style lang="less">
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.sw-input-number {
  width: fit-content;
  height: fit-content;
  padding: 3px;
  background: #FFFFFF;
  border: 1px solid @grey-500;

  > div {
    max-width: none;
    > div {
      height: auto;
      align-items: center;
    }
  }

  button {
    width: 48px !important;
    height: 48px !important;
    border-radius: 0;
    &[disabled] {
      cursor: default;
    }
    + div {
      width: 56px;
      margin: 0 0 !important;
      > div {
        border: none !important;
      }
      input {
        font-size: 14px;
      }
    }

    &:first-child {
      background-color: #FFFFFF;
      border-color: var(--sw-color-main-500) !important;
      svg {
        fill: var(--sw-color-main-500);
      }
      &:hover { background-color: var(--sw-color-main-200); }
      &:active { background-color: var(--sw-color-main-300) !important; }
      &[disabled] {
        background-color: #FFFFFF !important;
        border-color: var(--sw-color-main-300) !important;
        svg {
          fill: var(--sw-color-main-300);
        }
      }
    }

    &:last-child {
      background-color: var(--sw-color-main-500);
      border-color: transparent;
      svg {
        fill: #FFFFFF;
      }
      &:hover { background-color: var(--sw-color-main-600) !important; }
      &:active { background-color: var(--sw-color-main-700) !important; }
      &[disabled] { background-color: var(--sw-color-main-300) !important; }
    }
  }

  &&_inverted {
    background: var(--sw-color-main-000);

    button {
      background-color: var(--sw-color-main-000);

      &:last-child {
        border-color: var(--sw-color-main-500);

        &:hover { background-color: var(--sw-color-main-200) !important; }
      }
    }
  }

  &_roundings_none { border-radius: 0; }
  &_roundings_small { border-radius: 10px; }
  &_roundings_large { border-radius: 32px; }

  &_roundings_none button { border-radius: 0 !important; }
  &_roundings_small button { border-radius: 8px !important; }
  &_roundings_large button { border-radius: 32px !important; }

  &_large button {
    width: 48px !important;
    height: 48px !important;
  }

  &_small button {
    width: 32px !important;
    height: 32px !important;
    .sw-input-number_roundings_small & {
      border-radius: 6px;
    }
    + div {
      width: 40px;
      > div {
        height: 32px;
      }
    }
  }

  &_inverted button + div > div {
    background: var(--sw-color-main-000);
  }

  &_inverted input {
    color: var(--sw-color-main-500) !important;
  }

  &_disabled {
    opacity: 0;
  }
}
</style>
