import ToastNotification from '@@/website/components/ToastNotification'
import { ROUNDINGS } from '@@/framework/types'

// noinspection JSUnusedGlobalSymbols
export default async ({ app }, inject) => {
  const getToastContent = (props = {}, listeners = {}) => ({
    component: ToastNotification,
    props: {
      cancelText: app.i18n.messages[app.i18n.locale]?.text_cancel,
      fontName: app.$simlaweb.settings.fonts.texts,
      ...props
    },
    listeners
  })

  const getToastOptions = (options = {}) => ({
    toastClassName: {
      [ROUNDINGS.NONE]: 'Vue-Toastification__toast_roundings_none',
      [ROUNDINGS.SMALL]: 'Vue-Toastification__toast_roundings_small',
      [ROUNDINGS.LARGE]: 'Vue-Toastification__toast_roundings_large'
    }[app.$simlaweb.settings.roundings],
    ...options
  })

  const undoOptions = {
    closeOnClick: false,
    timeout: 7000
  }

  await inject('notifications', {
    success: (msg, undo) => app.$toast.success(
      getToastContent({ msg, undo: !!undo }, undo ? { undo } : {}),
      getToastOptions(undo
        ? { ...undoOptions }
        : {}
      )
    ),
    info: msg => app.$toast.info(
      getToastContent({ msg }),
      getToastOptions()
    ),
    error: msg => app.$toast.error(
      getToastContent({ msg }),
      getToastOptions()
    )
  })
}
