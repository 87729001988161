export default {
  en: {
    months: [
      { full: 'January', short: 'Jan' },
      { full: 'February', short: 'Feb' },
      { full: 'March', short: 'Mar' },
      { full: 'April', short: 'Apr' },
      { full: 'May', short: 'May' },
      { full: 'June', short: 'June' },
      { full: 'July', short: 'July' },
      { full: 'August', short: 'Aug' },
      { full: 'September', short: 'Sept' },
      { full: 'October', short: 'Oct' },
      { full: 'November', short: 'Nov' },
      { full: 'December', short: 'Dec' }
    ],
    weekdays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    firstDayOfWeek: 0
  },
  es: {
    months: [
      { full: 'Enero', short: 'Ene' },
      { full: 'Febrero', short: 'Feb' },
      { full: 'Marzo', short: 'Mar' },
      { full: 'Abril', short: 'Abr' },
      { full: 'Mayo', short: 'May' },
      { full: 'Junio', short: 'Jun' },
      { full: 'Julio', short: 'Jul' },
      { full: 'Agosto', short: 'Ago' },
      { full: 'Septiembre', short: 'Sep' },
      { full: 'Octubre', short: 'Oct' },
      { full: 'Noviembre', short: 'Nov' },
      { full: 'Diciembre', short: 'Dic' }
    ],
    weekdays: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    firstDayOfWeek: 0
  },
  ru: {
    months: [
      { full: 'Январь', short: 'Янв' },
      { full: 'Февраль', short: 'Фев' },
      { full: 'Март', short: 'Мар' },
      { full: 'Апрель', short: 'Апр' },
      { full: 'Май', short: 'Май' },
      { full: 'Июнь', short: 'Июн' },
      { full: 'Июль', short: 'Июл' },
      { full: 'Август', short: 'Авг' },
      { full: 'Сентябрь', short: 'Сен' },
      { full: 'Октябрь', short: 'Окт' },
      { full: 'Ноябрь', short: 'Ноя' },
      { full: 'Декабрь', short: 'Дек' }
    ],
    weekdays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    firstDayOfWeek: 1
  }
}
