<template>
    <div
        :class="{
            'sw-calendar': true,
            'sw-calendar_roundings_none': $simlaweb.settings.roundings === ROUNDINGS.NONE,
            'sw-calendar_roundings_small': $simlaweb.settings.roundings === ROUNDINGS.SMALL,
            'sw-calendar_roundings_large': $simlaweb.settings.roundings === ROUNDINGS.LARGE,
        }"
        :style="styles"
    >
        <template v-if="view.mode === VIEWS.DAYS">
            <SwCalendarNavigation
                class="sw-calendar__navigation"
                @main="view.mode = VIEWS.MONTHS"
                @next="showMonth(view.date.getMonth() + 1)"
                @prev="showMonth(view.date.getMonth() - 1)"
            >
                {{ months[view.date.getMonth()].full }}
            </SwCalendarNavigation>

            <div class="sw-calendar__table">
                <div class="sw-calendar__row">
                    <div
                        v-for="(name, index) in weekdays"
                        :key="'weekday-' + name"
                        class="sw-calendar__cell sw-calendar__cell_weekday"
                        @click.stop="showMonth(index)"
                    >
                        {{ name }}
                    </div>
                </div>

                <div
                    v-for="(week, index) in daysOfMonth"
                    :key="'week-' + index"
                    class="sw-calendar__row"
                >
                    <div
                        v-for="day in week"
                        :key="'day-of-month-' + day"
                        :class="{
                            'sw-calendar__cell': true,
                            'sw-calendar__cell_day': true,
                            'sw-calendar__cell_day_muted': !sameByMonth(view.date, day),
                            'sw-calendar__cell_current': sameByDay(today, day),
                            'sw-calendar__cell_selected': value && sameByDay(value, day),
                        }"
                        @click.stop="onDayClick(day)"
                    >
                        {{ day.getDate() }}
                    </div>
                </div>
            </div>
        </template>

        <template v-else-if="view.mode === VIEWS.MONTHS">
            <SwCalendarNavigation
                class="sw-calendar__navigation"
                @main="view.mode = VIEWS.YEARS"
                @next="showYear(view.date.getFullYear() + 1)"
                @prev="showYear(view.date.getFullYear() - 1)"
            >
                {{ view.date.getFullYear() }}
            </SwCalendarNavigation>

            <div class="sw-calendar__table">
                <div
                    v-for="({ short }, index) in months"
                    :key="short"
                    :class="{
                        'sw-calendar__cell': true,
                        'sw-calendar__cell_month': true,
                        'sw-calendar__cell_current':
                            today.getMonth() === index &&
                            today.getFullYear() === view.date.getFullYear(),
                        'sw-calendar__cell_selected':
                            value &&
                            value.getMonth() === index &&
                            value.getFullYear() === view.date.getFullYear(),
                    }"
                    @click.stop="showMonth(index)"
                >
                    {{ short }}
                </div>
            </div>
        </template>

        <template v-else-if="view.mode === VIEWS.YEARS">
            <SwCalendarNavigation
                class="sw-calendar__navigation"
                @next="showNextDecade"
                @prev="showPrevDecade"
            >
                {{ view.decade[0] }}&ndash;{{ view.decade[view.decade.length - 1] }}
            </SwCalendarNavigation>

            <div class="sw-calendar__table">
                <div
                    v-for="year in view.decade"
                    :key="year"
                    :class="{
                        'sw-calendar__cell': true,
                        'sw-calendar__cell_year': true,
                        'sw-calendar__cell_current': today.getFullYear() === year,
                        'sw-calendar__cell_selected': value && value.getFullYear() === year,
                    }"
                    @click.stop="showYear(year)"
                >
                    {{ year }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import SwCalendarNavigation from './SwCalendarNavigation'

import I18N from './i18n'
import { ROUNDINGS } from '@@/framework/types'
import { VIEWS } from './enum'

/**
 * @param {Date} date
 * @returns {Date}
 */
const getDateWithoutTime = date => new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate()
)

/**
 * @param {Date} day
 * @param {number} firstDayOfWeek
 * @return {Array<Date>}
 */
const getDaysOfWeek = (day, firstDayOfWeek) => {
  const days = []
  const offset = day.getDay() === 0 && firstDayOfWeek > 0 ? 7 : 0

  for (let i = 0; i < 7; i++) {
    days.push(getDateWithoutTime(new Date(
      day.getFullYear(),
      day.getMonth(),
      day.getDate() - day.getDay() + i + firstDayOfWeek - offset
    )))
  }

  return days
}

/**
 * @param {Date} firstDayOfMonth
 * @param {number} firstDayOfWeek
 * @return {Array<Array<Date>>}
 */
const getDaysOfMonth = (firstDayOfMonth, firstDayOfWeek) => {
  const days = []

  for (let i = 0; i < 6; i++) {
    days.push(getDaysOfWeek(new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth(),
      1 + i * 7
    ), firstDayOfWeek))
  }

  return days
}

/**
 * @param {number} year
 * @returns {number[]}
 */
const getDecade = (year) => {
  const first = year - (year % 10) - 1

  return Array.from({ length: 12 }, (_, i) => i + first)
}

/**
 * @param {Date} a
 * @param {Date} b
 * @returns {boolean}
 */
const sameByMonth = (a, b) => a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear()

/**
 * @param {Date} a
 * @param {Date} b
 * @returns {boolean}
 */
const sameByDay = (a, b) => a.getDate() === b.getDate() && sameByMonth(a, b)

export default {
  name: 'SwCalendar',

  components: {
    SwCalendarNavigation
  },

  props: {
    value: {
      validator: date => date === null || date instanceof Date,
      default: null
    },

    nullable: {
      type: Boolean,
      default: false
    },

    inverted: {
      type: Boolean,
      default: false
    }
  },

  data () {
    const date = getDateWithoutTime(this.value || new Date())

    return {
      view: {
        /** @type {VIEWS} */
        mode: VIEWS.DAYS,
        date,
        decade: getDecade(date.getFullYear())
      }
    }
  },

  computed: {
    ROUNDINGS: () => ROUNDINGS,

    VIEWS: () => VIEWS,

    locale () {
      return this.$i18n ? this.$i18n.locale : 'en'
    },

    today: () => getDateWithoutTime(new Date()),

    firstDayOfWeek () {
      return I18N[this.locale].firstDayOfWeek
    },

    weekdays () {
      const first = this.firstDayOfWeek
      const names = I18N[this.locale].weekdays

      return [
        ...names.slice(first),
        ...names.slice(0, first)
      ]
    },

    daysOfMonth () {
      return getDaysOfMonth(this.view.date, this.firstDayOfWeek)
    },

    months () {
      return I18N[this.locale].months
    },

    styles () {
      return this.inverted
        ? {
            '--sw-color-main-500': 'rgba(255, 255, 255, 1)',
            '--sw-color-main-600': 'rgba(255, 255, 255, 0.8)',
            '--sw-color-main-700': 'rgba(255, 255, 255, 0.6)',
            '--sw-color-main-300': 'rgba(255, 255, 255, 0.4)',
            '--sw-color-main-200': 'rgba(255, 255, 255, 0.2)',
            '--sw-color-main-000': 'rgba(255, 255, 255, 0)'
          }
        : {}
    }
  },

  watch: {
    value (curr, prev) {
      if (curr !== prev) {
        const date = getDateWithoutTime(curr || new Date())

        this.view.date = date
        this.view.decade = getDecade(date.getFullYear())
      }
    }
  },

  methods: {
    sameByDay,
    sameByMonth,

    /**
     * @param {Date} day
     * @internal
     */
    onDayClick (day) {
      const value = this.value

      if (value && sameByDay(value, day) && !this.nullable) {
        return
      }

      if (!sameByMonth(this.view.date, day)) {
        this.view.date = new Date(
          day.getFullYear(),
          day.getMonth()
        )
      }

      this.$emit('input', value && sameByDay(value, day) ? null : day)
    },

    showMonth (monthIndex) {
      const year = this.view.date.getFullYear()

      this.view.date = new Date(year, monthIndex)
      this.view.mode = VIEWS.DAYS
    },

    showYear (year) {
      const month = this.view.date.getMonth()

      this.view.date = new Date(year, month)
      this.view.mode = VIEWS.MONTHS
    },

    showPrevDecade () {
      this.view.decade = getDecade(this.view.decade[1] - 10)
    },

    showNextDecade () {
      this.view.decade = getDecade(this.view.decade[1] + 10)
    }
  }
}
</script>

<style lang="less">
@import (reference) "./assets/Web.less";

.sw-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 284px;
  min-width: 284px;
  width: max-content;
  padding: 16px;
  background: #FFFFFF;
  border-radius: 8px;
  font-family: var(--sw-font-texts);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  position: absolute;

  &__navigation {
    margin-bottom: 8px;
  }

  &__table {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__row {
    display: flex;
    flex-direction: row;
  }

  &__cell {
    display: inline-block;
    height: 36px;
    padding: 0;
    line-height: 36px;
    text-align: center;
    vertical-align: middle;

    &_weekday {
      width: 36px;
      font-size: 12px;
      color: #8a96a6;
    }

    &_day {
      width: 36px;
      cursor: pointer;

      &:hover { background: @brand-100; }

      &_muted {
        color: #8a96a6;
      }
    }

    &_month,
    &_year {
      width: 63px;
      cursor: pointer;
    }

    &_month:hover { background: @brand-100; }
    &_year:hover { background: @brand-100; }

    &_current {
      font-weight: 600;
    }

    &_selected {
      background: var(--sw-color-main-500) !important;
      font-weight: 400;
      color: #FFFFFF;
      border-radius: 4px;
    }
  }

  &_roundings_none &__cell { border-radius: 0; }
  &_roundings_small &__cell { border-radius: 4px; }
  &_roundings_large &__cell { border-radius: 32px; }
}
</style>
