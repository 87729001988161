<template>
    <SwModalSidebar
        :opened="$store.getters['modals/opened']('catalogFilters')"
    >
        <SidebarHeader
            :class="$style['sidebar__header']"
            @close="close"
        >
            {{ $t('filters') }}
        </SidebarHeader>

        <div :class="[$style['sidebar__separator'], 'sw-separator']" />

        <FilterForm
            v-if="filters && filters.length"
            :filters="filters"
            :class="$style['sidebar__form']"
        />

        <footer :class="$style['sidebar__footer']">
            <SwButton
                :class="$style['sidebar__button']"
                :light="!isMobile"
                small
                @click="close"
            >
                {{ btnCloseTitle }}
            </SwButton>
            <CatalogFiltersResetter v-if="showResetButton">
                <template #default="{ reset }">
                    <SwButton
                        :class="$style['sidebar__button']"
                        borderless
                        small
                        transparent
                        inverted
                        @click="() => {reset(); close()}"
                    >
                        {{ $t('resetAll') }}
                    </SwButton>
                </template>
            </CatalogFiltersResetter>
        </footer>
    </SwModalSidebar>
</template>

<script>
import FilterForm from '@@/website/components/common/FilterForm'
import SidebarHeader from '@@/website/components/common/SidebarHeader'
import CatalogFiltersResetter from '@@/website/components/common/CatalogFiltersResetter'

import { GET_CATALOG_FILTERS } from '@@/graphQL/web'
import { mapState } from 'vuex'

export default {
  name: 'ModalCatalogFilter',

  components: {
    FilterForm,
    SidebarHeader,
    CatalogFiltersResetter,
  },

  data: () => ({
    filters: null,
    isMobile: false,
  }),

  computed: {
    ...mapState({
      totalProductsCount: state => state.catalog.totalProductsCount,
      hasCurrentCategoryId: state => !!state.catalog.currentCategoryId,
      hasFilters: state => !!state.catalog.filters.length,
      hasSort: state => state.catalog.sort?.id !== state.catalog.defaultSort
    }),

    showResetButton () {
      return this.hasCurrentCategoryId || this.hasFilters || this.hasSort
    },

    btnCloseTitle () {
      return this.isMobile ? `${this.$t('text_show')} ${this.totalProductsCount}` : this.$t('text_close')
    }
  },

  watch: {
    $route: {
      async handler () {
        this.filters = await this.getFilters()
      }
    }
  },

  async beforeMount () {
    this.filters = await this.getFilters()
  },

  mounted () {
    const mobileMedia = window.matchMedia('(max-width: 768px)')
    const setMobile = () => {
      this.isMobile = mobileMedia.matches
    }

    setMobile()

    if (typeof mobileMedia?.addEventListener === 'function') {
      mobileMedia.addEventListener('change', setMobile)
      this.$once('hook:beforeDestroy', () => {
        mobileMedia.removeEventListener('change', setMobile)
      })
    }
  },

  methods: {
    close () {
      this.$store.commit('modals/close', 'catalogFilters')
    },

    async getFilters () {
      const { data } = await this.$apollo.query({
        query: GET_CATALOG_FILTERS,
        variables: {
          siteCode: this.$store.state.siteCode,
          slug: this.$route.params.product_group_slug || ''
        }
      })

      return data.filters
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.sidebar {
  &__header {
    position: relative;
    background-color: #FFFFFF;
  }

  &__separator:global(.sw-separator) {
    display: none;
    margin-top: 0;
    margin-bottom: 0;

    @media screen and (max-width: @bp-mobile-small) {
      display: block;
    }
  }

  &__button {
    @media screen and (max-width: @bp-mobile-small) {
      min-width: auto;
    }
  }

  &__form {
    padding: 16px 12px 16px 8px;
    overflow-y: auto;

    @media screen and (max-width: @bp-tablet) {
      width: 100%;
    }

    @media screen and (max-width: @bp-mobile-small) {
      padding: 16px 8px 12px 8px;
      margin-bottom: 0;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 20px;
    margin-top: auto;
    background-color: @grey-200;

    @media screen and (max-width: @bp-mobile-small) {
      padding: 16px;
    }
  }
}
</style>
