<template>
    <div
        :class="{
            'sw-input-dropdown': true,
            'sw-input-dropdown_filled': filled,
            'sw-input-dropdown_active': active && !disabled,
            'sw-input-dropdown_disabled': disabled,
        }"
    >
        <label class="sw-input-dropdown__body">
            <input
                type="text"
                :value="stringify(value)"
                :placeholder="label"
                :disabled="disabled"
                class="sw-input-dropdown__input"
                readonly
                @click.stop="active ? close() : open()"
            >

            <span
                v-if="filled"
                class="sw-input-dropdown__label"
            >
                {{ label }}
            </span>

            <SvgIconCaretDown class="sw-input-dropdown__caret" />
        </label>

        <div class="sw-input-dropdown__popup">
            <template v-for="(option, index) in options">
                <div
                    :key="index"
                    class="sw-input-dropdown__option"
                    @click="select(option)"
                >
                    <slot name="option" :option="option">
                        {{ option }}
                    </slot>

                    <SvgIconCheckMark
                        v-if="compare(option, value)"
                        class="sw-input-dropdown__option-check-mark"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import SvgIconCaretDown from '@@/framework/icons/caret-down.svg'
import SvgIconCheckMark from '@@/framework/icons/check-mark.svg'

export default {
  name: 'SwInputDropdown',

  components: {
    SvgIconCaretDown,
    SvgIconCheckMark
  },

  props: {
    label: {
      type: String,
      default: ''
    },

    value: {
      validator: () => true,
      default: null
    },

    options: {
      type: Array,
      default: () => []
    },

    compare: {
      type: Function,
      default: () => (a, b) => a === b
    },

    stringify: {
      type: Function,
      default: () => a => a
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    active: false
  }),

  computed: {
    filled () {
      return !!this.value
    }
  },

  beforeDestroy () {
    document.removeEventListener('click', this.onMissClick)
  },

  methods: {
    select (option) {
      this.$emit('select', option)
      this.close()
    },

    open () {
      this.active = true
      document.addEventListener('click', this.onMissClick, true)
    },

    close () {
      this.active = false
      document.removeEventListener('click', this.onMissClick)
    },

    onMissClick (e) {
      if (e.target.closest('.sw-input-dropdown') !== this.$el) {
        this.close()
      }
    }
  }
}
</script>

<style lang="less">
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.sw-input-dropdown {
  display: inline-block;
  font-family: var(--sw-font-texts);
  position: relative;

  &, & * {
    box-sizing: border-box;
  }

  &__body {
    display: block;
  }

  &__input {
    max-width: 230px;
    height: 56px;
    padding: 15px 48px 15px 15px;
    border: 1px solid #C7CDD4;
    border-radius: 8px;
    outline: none;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    transition: .2s;

    &:hover { border-color: #4C6EF5; }
  }

  &_active &__input {
    box-shadow: 0 0 0 2px @brand-200;
  }

  &_filled &__input {
    padding: 25px 48px 5px 15px;
  }

  &_disabled &__input {
    color: @grey-800;
  }

  &__label {
    padding: 16px 48px 16px 16px;
    line-height: 22px;
    color: @grey-700;
    transition: .2s;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;

    .sw-input-dropdown_filled & {
      padding: 6px 48px 6px 16px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__caret {
    color: #14142A;
    transform: translate(-50%, -50%);
    transition: .2s;
    position: absolute;
    right: 25px;
    top: 50%;
  }

  &_active &__caret {
    transform: translate(-50%, -50%) rotate(180deg);
  }

  &__popup {
    display: none;
    min-width: 100%;
    padding: 8px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(31, 31, 46, 0.12);
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
  }

  &_active &__popup { display: block; }
  &_disabled &__popup { display: none; }

  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px;
    border-radius: 6px;
    line-height: 24px;
    cursor: pointer;

    &:hover {
      background: #edf2ff;
    }

    & + & {
      margin-top: 6px;
    }
  }

  &__option-check-mark {
    color: #12B886;
  }
}
</style>
