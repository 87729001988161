var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',_vm._b({class:{
        'sw-input': true,
        'sw-input_filled': !!_vm.value,
        'sw-input_labelled': _vm.label,
        'sw-input_search': _vm.search,
        'sw-input_focused': _vm.focused,
        'sw-input_invalid': _vm.invalid,
        'sw-input_disabled': _vm.disabled,
        'sw-input_multiline': _vm.multiline,
        'sw-input_roundings_none': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.NONE,
        'sw-input_roundings_small': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.SMALL,
        'sw-input_roundings_large': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.LARGE && !_vm.multiline,
        'sw-input_roundings_large_multiline': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.LARGE && _vm.multiline,
        'sw-input_dense': _vm.dense,
        'sw-input_required': _vm.required,
    }},'span',_vm.$attrs,false),[_c('label',{staticClass:"sw-input__inner"},[_c(_vm.variants.length > 0 ? 'SwInputAutocompletion' : 'div',{tag:"component",attrs:{"list":_vm.variants,"open":_vm.focused && _vm.showVariants,"area-focus":_vm.variants.length > 0 ? _vm.focused : null},on:{"select":function($event){_vm.variants.length > 0 ? _vm.onSelect($event) : null}}},[_c(_vm.multiline ? 'textarea' : 'input',{directives:[{name:"awesome-mask",rawName:"v-awesome-mask",value:(_vm.mask),expression:"mask"}],ref:"input",tag:"component",staticClass:"sw-input__input",attrs:{"id":_vm.name,"name":_vm.name,"type":_vm.type,"value":_vm.value || _vm.value !== 0 ? _vm.value : '',"autocomplete":_vm.autocomplete,"readonly":_vm.readonly,"disabled":_vm.disabled},on:{"input":function($event){return _vm.onInput($event.target.value)},"focus":_vm.onFocus,"blur":_vm.onBlur,"change":function($event){return _vm.$emit('change', $event.target.value)},"keyup":function (event) { return _vm.$emit('keyup', event); }}})],1),_vm._v(" "),(_vm.label)?_c('span',{staticClass:"sw-input__label"},[_vm._v("\n            "+_vm._s(_vm.label)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.search)?_c('span',{staticClass:"sw-input__search-icon"},[_c('SvgIconSearch')],1):_vm._e(),_vm._v(" "),(_vm.clearable && _vm.value && !_vm.disabled && !_vm.$slots.icon)?_c('span',{staticClass:"sw-input__right-icon",on:{"click":_vm.clear}},[_c('SvgIconCrossOutlined')],1):_vm._e(),_vm._v(" "),(_vm.$slots.icon)?_c('span',{staticClass:"sw-input__right-icon"},[_vm._t("icon")],2):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }