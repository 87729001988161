<template>
    <div :class="$style['tree']">
        <ul v-if="depth === 0" :class="$style['tree__subtree']">
            <TreeSubtree
                v-for="(item, index) in productGroupsTree"
                :key="index"
                :item="item"
                :selected-id="selectedId"
                @selectCategory="setSelected($event)"
                @closeModal="close"
            />
        </ul>

        <TreeInDepth
            v-else
            :tree="productGroupsTree"
            :selected-id="selectedId"
            @selectCategory="setSelected($event)"
            @closeModal="close"
        />
    </div>
</template>

<script>
import TreeInDepth from '@@/website/components/common/CatalogTree/TreeInDepth'
import TreeSubtree from '@@/website/components/common/CatalogTree/TreeSubtree'

import { GET_PRODUCT_GROUPS } from '@@/graphQL/web'

export default {
  name: 'CatalogTree',

  components: {
    TreeSubtree,
    TreeInDepth
  },

  data: () => ({
    selectedId: 0,
    productGroupsTree: null,
    update: 0
  }),

  apollo: {
    productGroupsTree: {
      query: GET_PRODUCT_GROUPS,
      variables () {
        return {
          siteCode: this.$store.state.siteCode
        }
      }
    }
  },

  computed: {
    depth () {
      const node = this.getNode(
        this.productGroupsTree,
        this.selectedId
      )

      return node ? node.depth : 0
    }
  },

  watch: {
    productGroupsTree: {
      immediate: true,
      handler () {
        this.init()
      }
    }
  },

  methods: {
    init () {
      const slug = this.$route.params.product_group_slug
      const tree = this.productGroupsTree || []

      const selectedNode = this.getNodeBySlug(tree, slug)

      this.selectedId = selectedNode ? selectedNode.id : 0
      this.$emit('category-name', selectedNode?.name || '')
      this.$store.commit('catalog/setCurrentCategoryId', this.selectedId)

      if (this.selectedId) {
        this.setSelected(this.selectedId)
      }
    },

    async setSelected (id) {
      this.selectedId = id
      const node = this.getNode(this.productGroupsTree, this.selectedId)

      this.$nextTick(() => {
        this.update++
      })

      if (node) {
        this.$store.commit('catalog/setCurrentCategoryId', this.selectedId)

        await this.$router.push({
          name: 'catalog-product_group_slug',
          params: { ...this.$route.params, product_group_slug: node.slug },
          query: { ...this.$route.query, sort: this.$route.query.sort }
        })
      }
    },

    getNode (tree, id) {
      return this.findNode(tree || [], node => node.id === id)
    },

    getNodeBySlug (tree, slug) {
      return this.findNode(tree || [], node => node.slug === slug)
    },

    findNode (tree, predicate) {
      if (!tree) {
        return undefined
      }

      for (let i = 0; i < tree.length; i++) {
        if (predicate(tree[i])) {
          return tree[i]
        }
      }

      for (let j = 0; j < tree.length; j++) {
        const node = this.findNode(tree[j].children, predicate)
        if (node) {
          return node
        }
      }

      return undefined
    },

    close () {
      this.$store.commit('modals/close', 'catalogTree')
    }
  }
}
</script>

<style lang="less" module>
.tree {
  display: block;
  width: 100%;

  ul&__subtree {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}
</style>
