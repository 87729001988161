import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { withClientState } from 'apollo-link-state'
import { createUploadLink } from 'apollo-upload-client'
import { defaultDataIdFromObject } from '@apollo/client/core'

import createAuthLink from './createAuthLink'
import createErrorLink from './createErrorLink'

const cache = new InMemoryCache({
  dataIdFromObject (res) {
    switch (res.__typename) {
      case 'PublicPageBlockPayload': return `PublicPageBlockPayload:${res.pivotId}`
      default: defaultDataIdFromObject(res)
    }
  }
})

export default ({ app, env, req, isDev }) => {
  const getCurrentUrl = () => {
    if (isDev) {
      return env.BASE_URL
    }

    if (typeof window !== 'undefined') {
      const hostUrl = window.__NUXT__?.config?.app?.HOST_URL
      if (hostUrl) {
        return hostUrl
      }
    }

    const host = typeof window !== 'undefined'
      ? window.location.host
      : req
        ? req.headers.host
        : undefined

    const isLocal = !host ||
      host.includes('localhost') ||
      host.endsWith('.test') ||
      host.endsWith('.local')

    return isLocal ? env.BASE_URL : `https://${host}/api`
  }

  app.store.commit('siteUrl', getCurrentUrl())

  const host = req?.headers?.host

  if (host && !host.includes('localhost')) {
    app.store.commit('siteHost', host)
  }

  const responseLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      for (const key in response.data) {
        if (!response.data[key]) {
          return response
        }

        if (response.data[key].message && !('checkDomain' in response.data)) {
          // Если был выполнен запрос НЕ checkDomain (админка: проверить доменное имя - никаких сообщений не надо выводить)
          if (response.data[key]?.success) {
            if (!['cartDelete', 'recoverySendLink', 'registerDomain', 'deleteDomain'].some(item => item in response.data)) {
              // Не выводить успешные сообщения для:
              // Если был выполнен запрос НЕ cartDelete (удалить товар из корзины)
              // Если был выполнен запрос НЕ recoverySendLink (восстановление пароля)
              if (!('cartAssign' in response.data)) {
                // Если был выполнен запрос НЕ cartAssign (добавить товар в корзину)
                if (!response.data?.publishConfig?.isFirstPublish) {
                  app.$notifications?.success(response.data[key].message)
                }
              } else if (app.store.state.settings.show_window_after_buy) {
                // Если в админке включена опция "Показывать окно «Товар добавлен в корзину», после нажатия «Купить»"
                app.$notifications.info(response.data[key].message)
              }
            }
          } else {
            app.$notifications?.error(response.data[key].message)
          }
        }
      }

      return response
    })
  })

  return {
    cache,
    link: ApolloLink.from([
      createAuthLink(app),
      responseLink,
      withClientState({}),
      createErrorLink(app),
      createUploadLink({
        uri: getCurrentUrl()
      })
    ]),
    defaultHttpLink: false
  }
}
