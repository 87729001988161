<template>
    <div
        :class="{
            [$style['collapse-box']]: true,
        }"
    >
        <div
            :class="{
                [$style['collapse-box__header']]: true,
                [$style['collapse-box__header_active']]: expanded,
            }"
            @click="$emit('toggle')"
        >
            <div class="sw-text">
                {{ title }}
            </div>

            <SvgIconCaretUp
                v-if="expanded"
                :class="$style['collapse-box__caret']"
            />

            <SvgIconCaretDown
                v-else
                :class="$style['collapse-box__caret']"
            />
        </div>

        <div
            v-show="expanded"
            :class="$style['collapse-box__body']"
        >
            <slot />
        </div>
    </div>
</template>

<script>
import SvgIconCaretDown from '@@/framework/icons/caret-down.svg'
import SvgIconCaretUp from '@@/framework/icons/caret-up.svg'

export default {
  name: 'CollapseBox',

  components: {
    SvgIconCaretDown,
    SvgIconCaretUp
  },

  props: {
    title: {
      type: String,
      default: 'Filter'
    },

    expanded: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.collapse-box {
  margin-bottom: 10px;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 12px 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;

    @media screen and (max-width: @bp-mobile-small) {
      padding-left: 8px;
      padding-right: 8px;
    }

    &:hover,
    &_active {
      background: @grey-200;
      border-radius: 8px;
    }
  }

  &__caret {
    color: #14142A;
    margin-right: 8px;
  }

  &__body {
    padding-left: 8px;
    padding-right: 12px;

    @media screen and (max-width: @bp-mobile-small) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
</style>
